enum JsonApiQueryFilterField {
    PERCENT_CHANGE_FROM = 'percent-change-from',
    VOLUME_FROM = 'volume-from',
    AVERAGE_VOLUME_10_DAY_FROM = 'average-volume-10-day-from',
    AVERAGE_VOLUME_3_MONTH_FROM = 'average-volume-3-month-from',
    PRICE_FROM = 'price-from',
    PRICE_TO = 'price-to',
    ATR_FROM = 'atr-from',
    ATR_TO = 'atr-to',
}

enum JsonApiQueryFilterOperator {
    CONTAIN = 'CONTAIN',
    EQ = 'EQ',
    IN = 'IN',
    GTE = 'GTE',
    LTE = 'LTE',
}

export {JsonApiQueryFilterField, JsonApiQueryFilterOperator}
