import {ID} from "../../../../_metronic/helpers";
import {ChartBarsType, ChartPeriod} from "../../component/chart/ScreenerChart";
import {ChartColorScheme, colorSchemes} from "../../../modules/screener/component/ScreenerChartColorScheme";
import {RequestFilterDto, RequestOrderType, RequestSortDto} from "../../utils/filter/FilterUtils";
import {SelectedTickerDto} from "../../../modules/screener/usa/UsaScreener";
import {SortField} from "../../../modules/screener/component/ScreenerSort";
import {v4 as uuid} from 'uuid';

export type UserSettingsDto = {
    id?: ID
    settings?: {
        language?: string
        theme?: string,
        pagination?: {
            pageSize?: number
        }
        markets?: {
            usaStocks?: MarketUserSettingsDto
            cryptoFutures?: MarketUserSettingsDto
            forex?: MarketUserSettingsDto
        }
    }
}

export type MarketUserSettingsDto = {
    barType: ChartBarsType
    showGrid: boolean
    defaultTimeFrame: ChartPeriod
    colorScheme: ChartColorScheme
    sort: RequestSortDto
    filter: RequestFilterDto | null
    filterTemplates: { [key: string]: any } | {}
    selectedFilterTemplateId: string | null
    selectedTickers: SelectedTickerDto[]
    miniChart: MiniChartSettingsDto
}

export type MiniChartSettingsDto = {
    opened: boolean
    position: {
        x: number
        y: number
    },
    size: {
        width: number
        height: number
    }
}

export const createDefaultUserSettings = (): UserSettingsDto => {
    return {
        id: uuid() as any as ID,
        settings: {
            language: 'en',
            theme: 'dark',
            pagination: {
                pageSize: 50
            },
            markets: {
                usaStocks: createDefaultMarketUserSettings(),
                cryptoFutures: createDefaultMarketUserSettings(),
                forex: createDefaultMarketUserSettings()
            }
        }
    }
}

export const createDefaultMarketUserSettings = (): MarketUserSettingsDto => {
    return {
        barType: ChartBarsType.OHLC,
        showGrid: false,
        defaultTimeFrame: ChartPeriod.D1,
        colorScheme: colorSchemes.GREEN_RED.light,
        sort: {
            field: SortField.TICKER,
            order: RequestOrderType.ASC
        },
        filter: null,
        filterTemplates: {

        },
        selectedFilterTemplateId: null,
        selectedTickers: [],
        miniChart: {
            opened: true,
            position: {
                x: 0,
                y: 0
            },
            size: {
                width: 700,
                height: 500
            }
        }
    }
}
