import {Checkbox, FormControl, FormControlLabel, TextField} from "@mui/material";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {useThemeMode} from "../../../../_metronic/partials";
import {useSearchParams} from "react-router-dom";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {toFloat, toInt} from "../../../common/utils/converter/TypeConverter";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {RequestFilterDto} from "../../../common/utils/filter/FilterUtils";
import {
    loadSelectedUsaFiltersFromStorage,
    saveSelectedUsaFiltersTemplateToStorage
} from "../../../common/component/storage/FiltersStorage";
import {KTSVG} from "../../../../_metronic/helpers";

type Props = {
    newFilterTemplateName: string | undefined
    setNewFilterTemplateName: Dispatch<SetStateAction<string | undefined>>
    requestFilter: RequestFilterDto
    totalFilters: number
    setTotalFilters: Dispatch<SetStateAction<number>>
    setRequestFilter: Dispatch<SetStateAction<RequestFilterDto>>
    setUpdateLoadedFilterTemplates: Dispatch<SetStateAction<boolean>>
}

const DEFAULT_MIN_VOLUME = '0'

const ScreenerFilter: FC<Props> = (props) => {
    const {t} = useTranslation()
    const {isDark} = useThemeMode()
    const [searchParams, setSearchParams] = useSearchParams()
    const {userSettings} = useUserSettingsContext()
    const {newFilterTemplateName, setNewFilterTemplateName, requestFilter, setRequestFilter, totalFilters, setTotalFilters, setUpdateLoadedFilterTemplates} = props
    const [newPercentChangeFromValue, setNewPercentChangeFromValue] = useState('0')
    const [newPercentChangeToValue, setNewPercentChangeToValue] = useState('0')
    const [newPriceToValue, setNewPriceToValue] = useState('0')
    const [newVolumeValue, setNewVolumeValue] = useState(DEFAULT_MIN_VOLUME)
    const [newAvg10DayVolumeValue, setNewAvg10DayVolumeValue] = useState(DEFAULT_MIN_VOLUME)
    const [newAvg10DayVolumeToValue, setNewAvg10DayVolumeToValue] = useState('0')
    const [newPriceValue, setNewPriceValue] = useState('0')
    const [newAtrValue, setNewAtrValue] = useState('0')
    const [newAtrToValue, setNewAtrToValue] = useState('0')
    const [newClosedUnderHighBarFromValue, setNewClosedUnderHighBarFromValue] = useState(false)
    const [newClosedUnderLowBarFromValue, setNewClosedUnderLowBarFromValue] = useState(false)
    const [newHasAbnormalVolumeValue, setNewHasAbnormalVolumeValue] = useState(false)
    const [newPassedAtrFromValue, setNewPassedAtrFromValue] = useState('0')
    const [newPassedAtrToValue, setNewPassedAtrToValue] = useState('0')
    const [newHasTodayEarningsValue, setNewHasTodayEarningsValue] = useState(false)
    const [newHasTomorrowEarningsValue, setNewHasTomorrowEarningsValue] = useState(false)
    const [newPremarketVolumeValue, setNewPremarketVolumeValue] = useState('0')
    const [showFilterTemplateNameModal, setShowFilterTemplateNameModal] = useState(false)
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    useEffect(() => {
        const savedRequestFilter = loadSelectedUsaFiltersFromStorage()

        if (!savedRequestFilter) {
            return
        }
        setNewVolumeValue(toNumberWithDotSeparator(savedRequestFilter?.volumeFrom?.toString() || DEFAULT_MIN_VOLUME))
        setNewAvg10DayVolumeValue(toNumberWithDotSeparator(savedRequestFilter?.avg10DayVolumeFrom?.toString() || DEFAULT_MIN_VOLUME))
        setNewAvg10DayVolumeToValue(toNumberWithDotSeparator(savedRequestFilter?.avg10DayVolumeTo?.toString() || '0'))
        setNewPercentChangeFromValue(savedRequestFilter?.percentChangeFrom?.toString() || '0')
        setNewPercentChangeToValue(savedRequestFilter?.percentChangeTo?.toString() || '0')
        setNewPriceValue(savedRequestFilter?.priceFrom?.toString() || '0')
        setNewPriceToValue(savedRequestFilter?.priceTo?.toString() || '0')
        setNewAtrValue(savedRequestFilter?.atrFrom?.toString() || '0')
        setNewAtrToValue(savedRequestFilter?.atrTo?.toString() || '0')
        setNewPassedAtrFromValue(savedRequestFilter?.passedAtrFrom?.toString() || '0')
        setNewPassedAtrToValue(savedRequestFilter?.passedAtrTo?.toString() || '0')
        setNewPremarketVolumeValue(savedRequestFilter?.premarketVolumeFrom?.toString() || '0')

        if (savedRequestFilter?.closedUnderHighBarFrom != null) {
            setNewClosedUnderHighBarFromValue(savedRequestFilter?.closedUnderHighBarFrom)
        }

        if (savedRequestFilter?.closedUnderLowBarFrom != null) {
            setNewClosedUnderLowBarFromValue(savedRequestFilter?.closedUnderLowBarFrom)
        }

        if (savedRequestFilter?.hasAbnormalVolumeValue != null) {
            setNewHasAbnormalVolumeValue(savedRequestFilter?.hasAbnormalVolumeValue)
        }

        if (savedRequestFilter?.hasTodayEarningsValue != null) {
            setNewHasTodayEarningsValue(savedRequestFilter?.hasTodayEarningsValue)
        }

        if (savedRequestFilter?.hasTomorrowEarningsValue != null) {
            setNewHasTomorrowEarningsValue(savedRequestFilter?.hasTomorrowEarningsValue)
        }
    }, [requestFilter])

    useEffect(() => {
        setTotalFilters(
            Object.entries(requestFilter).filter(entry => entry[1] && entry[1] !== 0).length
        )
    }, [requestFilter])

    const resetQueryFilters = () => {
        setSearchParams({})
        setRequestFilter({})

        setNewVolumeValue(DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeValue(DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeToValue('0')
        setNewPercentChangeFromValue('0')
        setNewPercentChangeToValue('0')
        setNewPriceValue('0')
        setNewPriceToValue('0')
        setNewAtrValue('0')
        setNewAtrToValue('0')
        setNewClosedUnderHighBarFromValue(false)
        setNewClosedUnderLowBarFromValue(false)
        setNewHasAbnormalVolumeValue(false)
        setNewPassedAtrFromValue('0')
        setNewPassedAtrToValue('0')
        setNewPremarketVolumeValue('0')
        setNewHasTodayEarningsValue(false)
        setNewHasTomorrowEarningsValue(false)

        /*const updatedSettings = userSettingsMapper.updateUsaScreener(
            userSettings,
            {
            volume: toInt(DEFAULT_MIN_VOLUME),
            avg10DayVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            avg10DayVolumeToValue: 0,
            avg3MonthVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            percentChange: 0,
            priceFrom: 0,
            priceTo: 0,
            atrFrom: 0,
            atrTo: 0,
        })
        removeSelectedUsaFiltersFromStorage()

        updateUserSettings(updatedSettings)*/
    }
    const applyQueryFilters = () => {
        const newRequestFilter = prepareNewRequestFilter()

        setRequestFilter(newRequestFilter)

        const existsFilters: {[key: string]: string} = {}

        searchParams.forEach((value, key) => {
            existsFilters[key] = value
        })
        existsFilters['page'] = '1'

        Object.entries(newRequestFilter).forEach(entry => {
            const key = entry[0]
            const value = entry[1]

            if (!value || value === 0) {
                delete existsFilters[key]
            } else {
                existsFilters[key] = value.toString()
            }
        })
        setSearchParams(existsFilters)

        /*const updatedSettings = userSettingsMapper.updateUsaScreener(
            userSettings,
            {
            volume: toInt(newVolumeValue),
            avg10DayVolumeValue: toInt(newAvg10DayVolumeValue),
            avg10DayVolumeToValue: toInt(newAvg10DayVolumeToValue),
            avg3MonthVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            percentChange: toFloat(newPriceValue),
            priceFrom: toFloat(newPriceValue),
            priceTo: toFloat(newPriceToValue),
            atrFrom: toFloat(newAtrValue),
            atrTo: toFloat(newAtrToValue),
        })
        saveSelectedUsaFiltersToStorage(newRequestFilter)

        updateUserSettings(updatedSettings)*/
    }
    const saveQueryFiltersTemplate = () => {
        const newRequestFilter = prepareNewRequestFilter()

        if (newFilterTemplateName) {
            saveSelectedUsaFiltersTemplateToStorage(newFilterTemplateName, newRequestFilter)
        }
        setShowFilterTemplateNameModal(false)
        setUpdateLoadedFilterTemplates(true)
    }
    const prepareNewRequestFilter = (): RequestFilterDto => {
        const newRequestFilter: RequestFilterDto = {}
        newRequestFilter.volumeFrom = toFloat(removeNumberDelimiter(newVolumeValue))
        newRequestFilter.avg10DayVolumeFrom = toFloat(removeNumberDelimiter(newAvg10DayVolumeValue))
        newRequestFilter.avg10DayVolumeTo = toFloat(removeNumberDelimiter(newAvg10DayVolumeToValue))
        newRequestFilter.avg3MonthVolumeFrom = 0
        newRequestFilter.percentChangeFrom = toFloat(newPercentChangeFromValue)
        newRequestFilter.percentChangeTo = toFloat(newPercentChangeToValue)
        newRequestFilter.priceFrom = toFloat(newPriceValue)
        newRequestFilter.priceTo = toFloat(newPriceToValue)
        newRequestFilter.atrFrom = toFloat(newAtrValue)
        newRequestFilter.atrTo = toFloat(newAtrToValue)
        newRequestFilter.closedUnderHighBarFrom = newClosedUnderHighBarFromValue
        newRequestFilter.closedUnderLowBarFrom = newClosedUnderLowBarFromValue
        newRequestFilter.hasAbnormalVolumeValue = newHasAbnormalVolumeValue
        newRequestFilter.passedAtrFrom = toFloat(newPassedAtrFromValue)
        newRequestFilter.passedAtrTo = toFloat(newPassedAtrToValue)
        newRequestFilter.hasTodayEarningsValue = newHasTodayEarningsValue
        newRequestFilter.hasTomorrowEarningsValue = newHasTomorrowEarningsValue
        newRequestFilter.premarketVolumeFrom = toFloat(newPremarketVolumeValue)

        return newRequestFilter
    }
    const closeModal = () => {
        setShowFilterTemplateNameModal(false)
    }
    const replaceNumberDelimiter = (value: string): string => {
        return value.replaceAll(',', '.')
    }
    const removeNumberDelimiter = (value: string): string => {
        return value.replaceAll('.', '')
    }
    const toNumberWithDotSeparator = (value: string): string => {
        return toInt(value.replaceAll('.', '')).toLocaleString('de-DE')
    }
    return (
        <div className='me-3'>
            <button className="btn btn-sm btn-flex btn-light-primary menu-dropdown position-relative" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i className="bi bi-filter fs-5 me-1"></i>
                {t('content.controls.buttons.filter')}
                {totalFilters > 0 && (<span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-light-danger">{totalFilters}</span>)}
            </button>
            <div className="menu menu-sub menu-sub-dropdown w-650px w-md-650px" data-kt-menu="true" style={{zIndex: 107, position: "fixed", inset: "0px 0px auto auto", margin: 0, transform: "transform: translate3d(-612.5px, 221px, 0px)"}} data-popper-placement="bottom-end">
                <div className="px-7 py-5">
                    <div className="text-gray-700 fs-5 text-dark fw-bold">{t('content.filter.title')}</div>
                </div>
                <div className="separator border-gray-200"></div>
                    <div className='row gx-5 gx-xl-10'>
                        <div className='col-xxl-5 mb-5 mb-xl-10'>
                            <div className="px-7 py-5">
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.currentVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.currentVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newVolumeValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewVolumeValue(fixedVolume)
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.avg10DayVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.avg10DayVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newAvg10DayVolumeValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewAvg10DayVolumeValue(fixedVolume)
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newAvg10DayVolumeToValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewAvg10DayVolumeToValue(fixedVolume)
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">{t('content.filter.price.title')}:</label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '5ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPriceValue}
                                                onChange={(event) => {
                                                    setNewPriceValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{m: 1, width: '5ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newPriceToValue}
                                                onChange={(event) => {
                                                    setNewPriceToValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputProps={{inputProps: {min: 0.0, max: 40}}}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.atr.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.atr.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '5ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newAtrValue}
                                                onChange={(event) => {
                                                    setNewAtrValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{m: 1, width: '5ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newAtrToValue}
                                                onChange={(event) => {
                                                    setNewAtrToValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputProps={{inputProps: {min: 0.0, max: 40}}}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.percentChange.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.percentChange.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPercentChangeFromValue}
                                                onChange={(event) => {
                                                    setNewPercentChangeFromValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newPercentChangeToValue}
                                                onChange={(event) => {
                                                    setNewPercentChangeToValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-7 mb-5 mb-xl-10'>
                            <div className="px-7 py-5">
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.closedUnderBar.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.closedUnderBar.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '85ch'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={newClosedUnderHighBarFromValue}
                                                              onChange={event => {
                                                                  setNewClosedUnderHighBarFromValue(event.target.checked)
                                                              }}
                                                              sx={{
                                                                  input: {
                                                                      color: (isDark ? '#999999' : '#555555'),
                                                                      borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                                  }
                                                              }}/>
                                                }
                                                label={t('content.filter.closedUnderBar.underHigh.label')}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={newClosedUnderLowBarFromValue}
                                                              onChange={event => {
                                                                  setNewClosedUnderLowBarFromValue(event.target.checked)
                                                              }}
                                                              sx={{
                                                                  input: {
                                                                      color: (isDark ? '#999999' : '#555555'),
                                                                      borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                                  }
                                                              }}/>
                                                }
                                                label={t('content.filter.closedUnderBar.underLow.label')}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.hasAbnormalVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.hasAbnormalVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '85ch'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={newHasAbnormalVolumeValue}
                                                              onChange={event => {
                                                                  setNewHasAbnormalVolumeValue(event.target.checked)
                                                              }}
                                                              sx={{
                                                                  input: {
                                                                      color: (isDark ? '#999999' : '#555555'),
                                                                      borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                                  }
                                                              }}/>
                                                }
                                                label={t('content.filter.hasAbnormalVolume.label')}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.passedAtr.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.passedAtr.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPassedAtrFromValue}
                                                onChange={(event) => {
                                                    setNewPassedAtrFromValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{m: 1, width: '5ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newPassedAtrToValue}
                                                onChange={(event) => {
                                                    setNewPassedAtrToValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputProps={{inputProps: {min: 0.0, max: 40}}}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.earnings.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.earnings.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '85ch'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={newHasTodayEarningsValue}
                                                              onChange={event => {
                                                                  setNewHasTodayEarningsValue(event.target.checked)
                                                              }}
                                                              sx={{
                                                                  input: {
                                                                      color: (isDark ? '#999999' : '#555555'),
                                                                      borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                                  }
                                                              }}/>
                                                }
                                                label={t('content.filter.earnings.label.today')}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={newHasTomorrowEarningsValue}
                                                              onChange={event => {
                                                                  setNewHasTomorrowEarningsValue(event.target.checked)
                                                              }}
                                                              sx={{
                                                                  input: {
                                                                      color: (isDark ? '#999999' : '#555555'),
                                                                      borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                                  }
                                                              }}/>
                                                }
                                                label={t('content.filter.earnings.label.tomorrow')}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.premarketVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.premarketVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{m: 1, width: '8ch'}}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPremarketVolumeValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewPremarketVolumeValue(fixedVolume)
                                                }}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="px-7 py-5">
                    <div className="d-flex justify-content-end">
                        <button type="button"
                                className="btn btn-sm btn-light btn-active-light-primary me-2"
                                data-kt-menu-dismiss="true"
                                onClick={resetQueryFilters}>
                            {t('content.common.controls.buttons.reset')}
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-primary me-2"
                                data-kt-menu-dismiss="true"
                                onClick={() => setShowFilterTemplateNameModal(true)}>
                            {t('content.controls.buttons.saveAsTemplate')}
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-primary"
                                data-kt-menu-dismiss="true"
                                onClick={applyQueryFilters}>
                            {t('content.common.controls.buttons.apply')}
                        </button>
                    </div>
                </div>
            </div>
            {showFilterTemplateNameModal && (
                <>
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_trade'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        {/* begin::Modal dialog */}
                        <div className='modal-dialog modal-dialog-centered mw-600px'>
                            {/* begin::Modal content */}
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    {/* begin::Modal title */}
                                    <h2 className='fw-bolder'>
                                        {t('content.filterTemplate.modal.templateName.title')}
                                    </h2>
                                    {/* end::Modal title */}
                                    {/* begin::Close */}
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                        onClick={closeModal}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                    {/* end::Close */}
                                </div>
                                {/* end::Modal title */}
                                {/* begin::Modal body */}
                                <div className='modal-body scroll-y'>
                                    <div className="mb-5">
                                        <FormControl variant="standard" sx={{m: 1, width: '64ch'}}>
                                            <TextField
                                                label={t('content.filterTemplate.modal.templateName.labels.name')}
                                                variant="standard"
                                                defaultValue={newFilterTemplateName}
                                                onChange={(event) => setNewFilterTemplateName(event.target.value)}
                                                sx={{
                                                    input: {
                                                        color: (isDark ? '#999999' : '#555555'),
                                                        borderBottom: "1px solid " + (isDark ? '#999999' : '#555555')
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {color: (isDark ? '#999999' : '#555555')}
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='row gx-5 gx-xl-10'>
                                        <div className='col-xxl-5 mb-5 mb-xl-10'>
                                            <div className="px-7 py-5"></div>
                                            <div className="px-7 py-5">
                                                <button type="button"
                                                        className="btn btn-sm btn-primary"
                                                        data-kt-menu-dismiss="true"
                                                        onClick={saveQueryFiltersTemplate}>
                                                    {t('content.common.controls.buttons.apply')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-backdrop fade show'></div>
                </>
            )}
        </div>
    )
}

export {
    ScreenerFilter
}
