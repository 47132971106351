import React from "react";
import {Route, Routes} from "react-router-dom";
import {MasterLayout} from "../../_metronic/layout/MasterLayout";
import {UsaScreener} from "../modules/screener/usa/UsaScreener";
import {CryptoFuturesScreener} from "../modules/screener/crypto/futures/CryptoFuturesScreener";
import {Page404} from "../modules/errors/404/Page404";

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                <Route path='screeners/usa' element={<UsaScreener/>}/>
                <Route path='screeners/crypto/futures' element={<CryptoFuturesScreener/>}/>
                {/*<Route path='screeners/crypto/spot' element={<UsaScreener/>}/>*/}
                {/*<Route path='screeners/forex' element={<ForexScreener/>}/>*/}
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}

export {PrivateRoutes}
