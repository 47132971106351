import {JsonApiQueryFilterOperator} from "../../dto/json-api/JsonApiQueryFilter";

export const DEFAULT_PAGE_SIZE = 50

export type RequestFilterDto = {
    volume24hFrom?: number,
    volume24hInUsdFrom?: number,
    volumeFrom?: number,
    avg10DayVolumeFrom?: number,
    avg10DayVolumeTo?: number,
    avg3MonthVolumeFrom?: number,
    percentChangeFrom?: number,
    percentChangeTo?: number,
    priceFrom?: number,
    priceTo?: number,
    atrFrom?: number,
    atrTo?: number,
    closedUnderHighBarFrom?: boolean,
    closedUnderLowBarFrom?: boolean,
    hasAbnormalVolumeValue?: boolean,
    hasTodayEarningsValue?: boolean,
    hasTomorrowEarningsValue?: boolean,
    passedAtrFrom?: number,
    passedAtrTo?: number,
    premarketVolumeFrom?: number,
    ticker?: string,
}

export type RequestSortDto = {
    field?: string,
    order?: RequestOrderType,
}

export enum RequestOrderType {
    ASC = "asc",
    DESC = "desc",
}

export type RequestPaginationDto = {
    page?: number,
    size?: number,
}

export const requestFilterToJsonApiFilter = (filter: RequestFilterDto): string[] => {
    const filters: string[] = []

    if (filter.volume24hFrom) {
        filters.push(`filter[volume24h][${JsonApiQueryFilterOperator.GTE}]=${filter.volume24hFrom}`)
    }
    if (filter.volume24hInUsdFrom) {
        filters.push(`filter[volume24hInUsd][${JsonApiQueryFilterOperator.GTE}]=${filter.volume24hInUsdFrom}`)
    }
    if (filter.volumeFrom) {
        filters.push(`filter[volume][${JsonApiQueryFilterOperator.GTE}]=${filter.volumeFrom}`)
    }
    if (filter.avg10DayVolumeFrom) {
        filters.push(`filter[average-volume-10-day][${JsonApiQueryFilterOperator.GTE}]=${filter.avg10DayVolumeFrom}`)
    }
    if (filter.avg10DayVolumeTo) {
        filters.push(`filter[average-volume-10-day-to][${JsonApiQueryFilterOperator.LTE}]=${filter.avg10DayVolumeTo}`)
    }
    if (filter.avg3MonthVolumeFrom) {
        filters.push(`filter[average-volume-3-month][${JsonApiQueryFilterOperator.GTE}]=${filter.avg3MonthVolumeFrom}`)
    }
    if (filter.percentChangeFrom) {
        filters.push(`filter[percent-change][${JsonApiQueryFilterOperator.GTE}]=${filter.percentChangeFrom}`)
    }
    if (filter.percentChangeTo) {
        filters.push(`filter[percent-change][${JsonApiQueryFilterOperator.LTE}]=${filter.percentChangeTo}`)
    }
    if (filter.priceFrom) {
        filters.push(`filter[price-from][${JsonApiQueryFilterOperator.GTE}]=${filter.priceFrom}`)
    }
    if (filter.priceTo) {
        filters.push(`filter[price-to][${JsonApiQueryFilterOperator.LTE}]=${filter.priceTo}`)
    }
    if (filter.atrFrom) {
        filters.push(`filter[atr-from][${JsonApiQueryFilterOperator.GTE}]=${filter.atrFrom}`)
    }
    if (filter.atrTo) {
        filters.push(`filter[atr-to][${JsonApiQueryFilterOperator.LTE}]=${filter.atrTo}`)
    }
    if (filter.closedUnderHighBarFrom) {
        filters.push(`filter[closed-under-high-bar-from][${JsonApiQueryFilterOperator.GTE}]=${filter.closedUnderHighBarFrom}`)
    }
    if (filter.closedUnderLowBarFrom) {
        filters.push(`filter[closed-under-low-bar-from][${JsonApiQueryFilterOperator.GTE}]=${filter.closedUnderLowBarFrom}`)
    }
    if (filter.hasAbnormalVolumeValue) {
        filters.push(`filter[has-abnormal-volume]=${filter.hasAbnormalVolumeValue}`)
    }
    if (filter.hasTodayEarningsValue) {
        filters.push(`filter[has-today-earning]=${filter.hasTodayEarningsValue}`)
    }
    if (filter.hasTomorrowEarningsValue) {
        filters.push(`filter[has-tomorrow-earning]=${filter.hasTomorrowEarningsValue}`)
    }
    if (filter.passedAtrFrom) {
        filters.push(`filter[passed-atr-from][${JsonApiQueryFilterOperator.GTE}]=${filter.passedAtrFrom}`)
    }
    if (filter.passedAtrTo) {
        filters.push(`filter[passed-atr-to][${JsonApiQueryFilterOperator.LTE}]=${filter.passedAtrTo}`)
    }
    if (filter.premarketVolumeFrom) {
        filters.push(`filter[premarket-volume-from][${JsonApiQueryFilterOperator.GTE}]=${filter.premarketVolumeFrom}`)
    }
    if (filter.ticker) {
        filters.push(`filter[ticker]=${filter.ticker}`)
    }
    return filters
}

export const queryFiltersToString = (filters: string[]): string => {
    if (filters.length > 0) {
        return filters.join('&').replaceAll('[', '%5B')
            .replaceAll(']', '%5D')
            .replaceAll('%2C', ',')
    }
    return ''
}

export const requestSortToJsonApiSort = (requestSort: RequestSortDto): string => {
    let sortField = 'ticker'
    let sortType = ''

    if (requestSort.field) {
        sortField = requestSort.field.toLowerCase() || sortField
    }

    if (requestSort.order) {
        if (requestSort.order === RequestOrderType.DESC) {
            sortType = '-'
        }
    }
    return `sort=${sortType}${sortField}`
}

export const queryPaginationToToJsonApiPagination = (pagination: RequestPaginationDto): string => {
    let page = 1
    let size = DEFAULT_PAGE_SIZE

    if (pagination.page) {
        page = pagination.page
    }

    if (pagination.size) {
        size = pagination.size
    }
    return `page%5Bnumber%5D=${page}&page%5Bsize%5D=${size}`
}
