import axios, {AxiosResponse} from "axios";
import {
    CandleDto,
    JsonApiUsaScreenerArrayDto,
    JsonApiUsaTickerScreenerChartDataArrayDto
} from "../../dto/screener/UsaScreenerDto";
import {
    queryFiltersToString,
    queryPaginationToToJsonApiPagination,
    RequestFilterDto,
    requestFilterToJsonApiFilter,
    RequestPaginationDto,
    RequestSortDto,
    requestSortToJsonApiSort
} from "../../utils/filter/FilterUtils";
import {JsonApiUsaScreenerTickerObjectDto} from "../../dto/screener/UsaScreenerTickerDto";
import {ChartPeriod} from "../../component/chart/ScreenerChart";

const API_URL = process.env.REACT_APP_SLM_SOFT_API_URL
const SLM_SOFT_SCREENER_URL = `${API_URL}/screeners/usa`
const SLM_SOFT_USA_SNP500_SCREENER_URL = `${API_URL}/screeners/usa/snp500`
const SLM_SOFT_EXPORT_SCREENER_TICKERS_URL = `${API_URL}/screeners/usa/export/tickers`
const SLM_SOFT_FOREX_SCREENER_URL = `${API_URL}/screeners/forex`
const SLM_SOFT_CRYPTO_FUTURES_EXPORT_SCREENER_TICKERS_URL = `${API_URL}/screeners/crypto/futures/export/tickers`
const SLM_SOFT_CRYPTO_FUTURES_SCREENER_URL = `${API_URL}/screeners/crypto/futures`
const SLM_SOFT_SCREENER_TICKER_CHART_DATA_URL = `${API_URL}/screeners/usa/charts/data`
const SLM_SOFT_CRYPTO_FUTURES_SCREENER_TICKER_CHART_DATA_URL = `${API_URL}/screeners/crypto/futures/charts/data`
const SLM_SOFT_FOREX_SCREENER_TICKER_CHART_DATA_URL = `${API_URL}/screeners/forex/charts/data`

const getAllUsaScreenerData = (
    defaultTimeFrame: ChartPeriod,
    searchTicker: string | undefined,
    filter: RequestFilterDto,
    sort: RequestSortDto,
    pagination: RequestPaginationDto
): Promise<JsonApiUsaScreenerArrayDto> => {
    const pageQuery = queryPaginationToToJsonApiPagination(pagination)

    if (searchTicker !== null) {
        filter.ticker = searchTicker
    }
    const filterQuery = queryFiltersToString(
        requestFilterToJsonApiFilter(filter)
    )
    const sortQuery = requestSortToJsonApiSort(sort)
    const timeFrameQuery = `defaultTimeFrame=${defaultTimeFrame}`
    const queryRequest = [pageQuery, filterQuery, sortQuery, timeFrameQuery].join('&')

    return axios.get(`${SLM_SOFT_SCREENER_URL}?${queryRequest}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerArrayDto>) => response.data)
}

const getUsaSnP500ScreenerData = (): Promise<JsonApiUsaScreenerArrayDto> => {
    return axios.get(`${SLM_SOFT_USA_SNP500_SCREENER_URL}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerArrayDto>) => response.data)
}

const getAllForexScreenerData = (
    defaultTimeFrame: ChartPeriod,
    filter: RequestFilterDto,
    sort: RequestSortDto,
    pagination: RequestPaginationDto
): Promise<JsonApiUsaScreenerArrayDto> => {
    const pageQuery = queryPaginationToToJsonApiPagination(pagination)
    const filterQuery = queryFiltersToString(
        requestFilterToJsonApiFilter(filter)
    )
    const sortQuery = requestSortToJsonApiSort(sort)
    const timeFrameQuery = `defaultTimeFrame=${defaultTimeFrame}`
    const queryRequest = [pageQuery, filterQuery, sortQuery, timeFrameQuery].join('&')

    return axios.get(`${SLM_SOFT_FOREX_SCREENER_URL}?${queryRequest}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerArrayDto>) => response.data)
}

const getAllCryptoFuturesScreenerData = (
    defaultTimeFrame: ChartPeriod,
    searchTicker: string | undefined,
    filter: RequestFilterDto,
    sort: RequestSortDto,
    pagination: RequestPaginationDto
): Promise<JsonApiUsaScreenerArrayDto> => {
    const pageQuery = queryPaginationToToJsonApiPagination(pagination)

    if (searchTicker !== null) {
        filter.ticker = searchTicker
    }
    const filterQuery = queryFiltersToString(
        requestFilterToJsonApiFilter(filter)
    )
    const sortQuery = requestSortToJsonApiSort(sort)
    const timeFrameQuery = `defaultTimeFrame=${defaultTimeFrame}`
    const queryRequest = [pageQuery, filterQuery, sortQuery, timeFrameQuery].join('&')

    return axios.get(`${SLM_SOFT_CRYPTO_FUTURES_SCREENER_URL}?${queryRequest}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerArrayDto>) => response.data)
}

const getUsaTickerScreenerChartDataByPeriod = (ticker: string, period: ChartPeriod): Promise<CandleDto[]> => {
    return axios.get(`${SLM_SOFT_SCREENER_TICKER_CHART_DATA_URL}?ticker=${ticker}&period=${period}`)
        .then((response: AxiosResponse<JsonApiUsaTickerScreenerChartDataArrayDto>) => {
            return response?.data
                ?.data
                ?.map((value) => value.attributes) || []
        })
}

const getCryptoFuturesTickerScreenerChartDataByPeriod = (ticker: string, period: string): Promise<CandleDto[]> => {
    return axios.get(`${SLM_SOFT_CRYPTO_FUTURES_SCREENER_TICKER_CHART_DATA_URL}?ticker=${ticker}&period=${period}`)
        .then((response: AxiosResponse<JsonApiUsaTickerScreenerChartDataArrayDto>) => {
            return response?.data
                ?.data
                ?.map((value) => value.attributes) || []
        })
}

const getForexTickerScreenerChartDataByPeriod = (ticker: string, period: string): Promise<CandleDto[]> => {
    return axios.get(`${SLM_SOFT_FOREX_SCREENER_TICKER_CHART_DATA_URL}?ticker=${ticker}&period=${period}`)
        .then((response: AxiosResponse<JsonApiUsaTickerScreenerChartDataArrayDto>) => {
            return response?.data
                ?.data
                ?.map((value) => value.attributes) || []
        })
}

const getAllUsaTickers = (filter: RequestFilterDto): Promise<JsonApiUsaScreenerTickerObjectDto> => {
    const filterQuery = queryFiltersToString(
        requestFilterToJsonApiFilter(filter)
    )
    return axios.get(`${SLM_SOFT_EXPORT_SCREENER_TICKERS_URL}?${filterQuery}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerTickerObjectDto>) => response.data)
}

const getAllCryptoFuturesTickers = (filter: RequestFilterDto): Promise<JsonApiUsaScreenerTickerObjectDto> => {
    const filterQuery = queryFiltersToString(
        requestFilterToJsonApiFilter(filter)
    )
    return axios.get(`${SLM_SOFT_CRYPTO_FUTURES_EXPORT_SCREENER_TICKERS_URL}?${filterQuery}`)
        .then((response: AxiosResponse<JsonApiUsaScreenerTickerObjectDto>) => response.data)
}

export {
    getAllUsaScreenerData,
    getUsaSnP500ScreenerData,
    getAllForexScreenerData,
    getAllCryptoFuturesScreenerData,
    getUsaTickerScreenerChartDataByPeriod,
    getCryptoFuturesTickerScreenerChartDataByPeriod,
    getForexTickerScreenerChartDataByPeriod,
    getAllUsaTickers,
    getAllCryptoFuturesTickers,
}
