/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {FC} from "react";
import {useThemeMode} from "../../partials";
import {useLayout} from "../core";
import {Link} from "react-router-dom";

const Footer: FC = () => {
    const {classes} = useLayout()
    const {isDark} = useThemeMode()

    const startYear = 2023
    const currentYear = new Date().getFullYear()

    return (
        <div className='footer d-flex flex-lg-column' id='kt_footer'>
            {/* begin::Container */}
            <div
                className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                {/* begin::Copyright */}
                <div className='text-dark order-2 order-md-1'>
                    <Link className='px-2' to="/">
                        <img alt="Logo" src={(isDark ? "media/logos/logo-compact-dark.svg" : "media/logos/logo-compact.svg")}
                             className="h-15px h-md-20px"/>
                    </Link>
                    <span className='text-muted me-2 text-gray-800'>
                        &copy;&nbsp;
                        {currentYear > startYear ? startYear + ' - ' + currentYear : currentYear}
                    </span>
                    <Link className='text-gray-800 text-hover-primary' to="/">
                        SLM Screener
                    </Link>
                    {/*<span className="text-gray-400 fs-9 p-2">v{process.env.REACT_APP_VERSION}</span>*/}
                </div>
                {/* end::Copyright */}

                {/* begin::Nav */}
                <ul className='menu menu-gray-800 menu-hover-primary order-1 '>
                    <li className='menu-item'>
                        <a href="https://t.me/slm_screener" target="_blank" className='menu-link px-2'>
                            <i className='bi bi-telegram fs-2'/>
                        </a>
                    </li>
                    <li className='menu-item'>
                        <a href="mailto:dev.slm.software@gmail.com"
                           title='dev.slm.software@gmail.com'
                           className='menu-link px-2'>
                            <i className='bi bi-envelope-check fs-2'/>
                        </a>
                    </li>
                </ul>
                {/* end::Nav */}
            </div>
            {/* end::Container */}
        </div>
    )
}

export {Footer}
