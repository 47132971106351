import axios from 'axios'
import {JsonApiAuthModel, JsonApiOAuth2StateModel, JsonApiProfileModel} from './_models'
import {EmptyJsonApiObjectDto} from "../../../common/dto/json-api/JsonApiDto";

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ID_URL = `${API_URL}/users/`
export const ACCES_TOKEN_URL = `${API_URL}/auth/token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const AUTHENTICATE_URL = `${API_URL}/auth/authenticate`
export const REGISTER_URL = `${API_URL}/auth/register`
export const OAUTH2_STATE_URL = `${API_URL}/auth/register/oauth2/state`
export const REGISTER_VIA_GOOGLE_URL = `${API_URL}/auth/register/oauth2/google`
export const REGISTRATION_CONFIRM_URL = `${API_URL}/auth/registration/confirm`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/password/forgot`

// Server should return AuthModel
export function getAccessToken() {
    return axios.get<JsonApiAuthModel>(ACCES_TOKEN_URL)
        .then(response => response?.data?.data?.attributes)
}

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post<JsonApiAuthModel>(LOGIN_URL, {
        email,
        password,
    })
}

// Server should return AuthModel
export function register(
    username: string,
    email: string,
    password: string,
    password_confirmation: string
) {
    return axios.post<JsonApiAuthModel>(REGISTER_URL, {
        username,
        email,
        password,
        password_confirmation,
    })
}

// Server should return AuthModel
export function registerByGoogle(code: string) {
    return axios.post<JsonApiAuthModel>(REGISTER_VIA_GOOGLE_URL, {code})
}

export function requestOauth2State() {
    return axios.get<JsonApiOAuth2StateModel>(OAUTH2_STATE_URL)
        .then(response => response?.data?.data?.attributes.state)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function confirmRegistration(confirmationCode: string) {
    return axios.post<Promise<EmptyJsonApiObjectDto>>(REGISTRATION_CONFIRM_URL, {confirmationCode: confirmationCode})
}

export function authenticateUser() {
    return axios.post<JsonApiProfileModel>(AUTHENTICATE_URL)
}

export function requestUserById(id: string) {
    return axios.get(GET_USER_BY_ID_URL + id)
}
