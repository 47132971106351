import {useSearchParams} from "react-router-dom";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {PaginationDto} from "../../dto/pagination/PaginationDto";
import {UserSettingsMapper} from "../../mapper/settings/UserSettingsMapper";
import {useUserSettingsContext} from "../../provider/settings/UserSettingsDataProvider";
import {toInt} from "../../utils/converter/TypeConverter";
import {useTranslation} from "react-i18next";
import {RequestFilterDto, RequestPaginationDto} from "../../utils/filter/FilterUtils";
import {updateUserSettings} from "../../request/settings/UserSettingsRequest";

type Props = {
    storageKey: string
    requestFilter: RequestFilterDto,
    requestPagination: RequestPaginationDto,
    setRequestPagination: Dispatch<SetStateAction<RequestPaginationDto>>,
    pagination: PaginationDto | undefined
    isLoading: boolean
    pageSizes?: Array<number> | undefined
    defaultPageSize?: number | undefined
}

const Pagination: FC<Props> = (props) => {
    const MAX_PAGES = 8
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const { pagination, storageKey, isLoading} = props
    const {pageSizes: sizes, defaultPageSize: defaultSize} = props
    const {requestPagination, setRequestPagination} = props
    const {requestFilter} = props
    const pageSizes = (sizes ? sizes : [5, 15, 25, 50, 100])
    const defaultPageSize = (defaultSize ? defaultSize : 15)
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])
    const [showStartEntry, setShowStartEntry] = useState(0)
    const [showEndEntry, setShowEndEntry] = useState(0)
    const [totalItems, setTotalItems] = useState('0')
    const [startIndex, setStartIndex] = useState(1)
    const [pages, setPages] = useState<Array<number>>([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize)

    useEffect(() => {
        const newPageSize = userSettings?.settings?.pagination?.pageSize || defaultPageSize

        setCurrentPageSize(newPageSize)

        setRequestPagination({
            page: currentPage,
            size: newPageSize
        })
    }, [userSettings?.settings?.pagination])

    useEffect(() => {
        setCurrentPage(1)
    }, [requestFilter])

    useEffect(() => {
        setTotalItems(pagination?.total?.toString() || '0')
    }, [pagination?.total])

    useEffect(() => {
        if (requestPagination.page) {
            setCurrentPage(requestPagination.page)
        }

        if (requestPagination.size) {
            setCurrentPageSize(requestPagination.size)
        }
    }, [requestPagination])

    useEffect(() => {
        const newTotalPages = Math.ceil((pagination?.total || 0) / (pagination?.maxSize || 1))

        setTotalPages(newTotalPages)

        if (!pagination) {
            return
        }
        const newPages = Array<number>()
        let newStartIndex = (currentPage > MAX_PAGES - 4 ? currentPage - 4 : 1)

        if (newStartIndex === 0) {
            setStartIndex(1)
        } else {
            setStartIndex(newStartIndex)
        }

        for (let i = newStartIndex; i < newStartIndex + MAX_PAGES; ++i) {
            if (i < newTotalPages) {
                newPages.push(i)
            }
        }
        setPages(newPages)

        let newShowStartEntry = (currentPage - 1) * currentPageSize + (currentPage < 2 ? 1 : 0)
        let newShowEndEntry = newShowStartEntry + currentPageSize - (currentPage < 2 ? 1 : 0)

        setShowStartEntry(newShowStartEntry > 0 ? newShowStartEntry : newShowEndEntry)

        if (newShowEndEntry > (pagination?.total || 0)) {
            setShowEndEntry(toInt(totalItems))
        } else {
            setShowEndEntry(newShowEndEntry)
        }

        if (newShowEndEntry < 1){
            setShowStartEntry(1)
        }
    }, [pagination, userSettings?.settings?.pagination, currentPage, currentPageSize, totalItems])

    const onPageChange = (page: number) => {
        if (isLoading) {
            return
        }

        if (page > 0 && page !== currentPage && page <= totalPages) {
            setCurrentPage(page)

            setRequestPagination({
                page: page,
                size: currentPageSize
            })
            const existsFilters: {[key: string]: string} = {}

            searchParams.forEach((value, key) => {
                existsFilters[key] = value
            })
            existsFilters['page'] = page.toString()
            existsFilters['size'] = currentPageSize.toString()

            setSearchParams(existsFilters)
        }
    }
    const onPageSizeChange = (pageSize: number) => {
        if (isLoading) {
            return
        }
        const newPage = 1

        setCurrentPage(newPage)
        setCurrentPageSize(pageSize)

        setRequestPagination({
            page: newPage,
            size: pageSize
        })
        const existsFilters: {[key: string]: string} = {}

        searchParams.forEach((value, key) => {
            existsFilters[key] = value
        })
        existsFilters['size'] = pageSize.toString()

        setSearchParams(existsFilters)

        const updatedUserSettings = userSettingsMapper.updatePaginationPageSize(userSettings, pageSize)

        updateUserSettings(updatedUserSettings, refetchUserSettingsData)
    }
    return (
        <div className='d-flex flex-stack flex-wrap pt-10'>
            <div
                className='fs-6 text-gray-700'>
                {t('pagination.description', {from: showStartEntry, to: showEndEntry, total: totalItems})}
            </div>

            <ul className='pagination'>
                <li>
                    <select className="form-select form-select-sm form-select-solid cursor-pointer"
                            value={currentPageSize}
                            onChange={(event) => onPageSizeChange(parseInt(event.currentTarget.value || defaultPageSize.toString()))}>
                        {pageSizes.map(pageSize => (
                            <option value={pageSize} key={pageSize}>{pageSize}</option>
                        ))}
                    </select>
                </li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li className='page-item'></li>
                <li key={storageKey + 'previous'} className={'page-item previous ' + (currentPage > 1 ? 'cursor-pointer' : 'disabled')}>
                    <span className='page-link' onClick={() => onPageChange(currentPage - 1)}>
                        <i className='previous'></i>
                    </span>
                </li>

                {startIndex > 1 && (
                    <>
                        <li key={storageKey + 1}
                            className={"page-item cursor-pointer " + (1 !== currentPage ? "" : "page-item active")}>
                                <span className='page-link' onClick={() => onPageChange(1)}>
                                    {1}
                                </span>
                        </li>
                        <li key={storageKey + totalPages}
                            className={"page-item cursor-pointer"}>
                                <span className='page-link'>
                                    ...
                                </span>
                        </li>
                    </>
                )}

                {pages.map(page => (
                    <li key={storageKey + page}
                        className={"page-item cursor-pointer " + (page !== currentPage ? "" : "page-item active")}>
                        <span className='page-link' onClick={() => onPageChange(page)}>
                            {page}
                        </span>
                    </li>
                ))}

                {totalPages > MAX_PAGES && currentPage < (totalPages - 4) && (
                    <>
                        <li key={storageKey + '...'}
                            className={"page-item cursor-pointer"}>
                                <span className='page-link'>
                                    ...
                                </span>
                        </li>
                    </>
                )}

                {currentPage <= totalPages && (
                    <li key={storageKey + totalPages}
                        className={"page-item cursor-pointer " + (totalPages !== currentPage ? "" : "page-item active")}>
                        <span className='page-link' onClick={() => onPageChange(totalPages)}>
                            {totalPages}
                        </span>
                    </li>
                )}

                <li key={storageKey + 'next'} className={'page-item next ' + (currentPage < totalPages ? 'cursor-pointer' : 'disabled')}>
                    <span className='page-link cursor-pointer' onClick={() => onPageChange(currentPage + 1)}>
                        <i className='next'></i>
                    </span>
                </li>
            </ul>
        </div>
    )
}

export {Pagination}
