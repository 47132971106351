import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {toAbsoluteUrl, WithChildren} from '../../helpers'
import {useThemeMode} from "../../partials";

const Content: FC<WithChildren> = ({children}) => {
    const {classes} = useLayout()
    const location = useLocation()
    const {isDark} = useThemeMode()


    useEffect(() => {
        DrawerComponent.hideAll()
    }, [location])

  return (
    <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain'
        style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/table-banner' + (isDark ? '-dark' : '') + '.png')})`,
        }}
    >
        <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
            {children}
        </div>
    </div>
  )
}

export {Content}
