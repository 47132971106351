import {FormControl, TextField} from "@mui/material";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {useThemeMode} from "../../../../_metronic/partials";
import {useSearchParams} from "react-router-dom";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {toFloat} from "../../../common/utils/converter/TypeConverter";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {RequestFilterDto} from "../../../common/utils/filter/FilterUtils";
import {loadSelectedCryptoFuturesFiltersFromStorage} from "../../../common/component/storage/FiltersStorage";

type Props = {
    requestFilter: RequestFilterDto,
    totalFilters: number,
    setTotalFilters: Dispatch<SetStateAction<number>>,
    setRequestFilter: Dispatch<SetStateAction<RequestFilterDto>>,
}

const DEFAULT_MIN_VOLUME = '0'

const CryptoScreenerFilter: FC<Props> = (props) => {
    const {t} = useTranslation()
    const {isDark} = useThemeMode()
    const [searchParams, setSearchParams] = useSearchParams()
    const {userSettings} = useUserSettingsContext()
    const {requestFilter, setRequestFilter, totalFilters, setTotalFilters} = props
    const [newPercentChangeFromValue, setNewPercentChangeFromValue] = useState('0')
    const [newPriceToValue, setNewPriceToValue] = useState('0')
    const [newVolume24hValue, setNewVolume24hValue] = useState(DEFAULT_MIN_VOLUME)
    const [newVolume24hInUsd, setNewVolume24hInUsdValue] = useState(DEFAULT_MIN_VOLUME)
    const [newVolumeValue, setNewVolumeValue] = useState(DEFAULT_MIN_VOLUME)
    const [newAvg10DayVolumeValue, setNewAvg10DayVolumeValue] = useState(DEFAULT_MIN_VOLUME)
    const [newAvg10DayVolumeToValue, setNewAvg10DayVolumeToValue] = useState('0')
    const [newPriceValue, setNewPriceValue] = useState('0')
    const [newAtrValue, setNewAtrValue] = useState('0')
    const [newAtrToValue, setNewAtrToValue] = useState('0')
    const [newPassedAtrFromValue, setNewPassedAtrFromValue] = useState('0')
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    useEffect(() => {
        const savedRequestFilter = loadSelectedCryptoFuturesFiltersFromStorage()

        if (!savedRequestFilter) {
            return
        }
        setNewVolume24hValue(toNumberWithDotSeparator(savedRequestFilter?.volume24hFrom?.toString() || '') || DEFAULT_MIN_VOLUME)
        setNewVolume24hInUsdValue(toNumberWithDotSeparator(savedRequestFilter?.volume24hInUsdFrom?.toString() || '') || DEFAULT_MIN_VOLUME)
        setNewVolumeValue(toNumberWithDotSeparator(savedRequestFilter?.volumeFrom?.toString() || '') || DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeValue(toNumberWithDotSeparator(savedRequestFilter?.avg10DayVolumeFrom?.toString() || '') || DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeToValue(toNumberWithDotSeparator(savedRequestFilter?.avg10DayVolumeTo?.toString() || '0'))
        setNewPercentChangeFromValue(savedRequestFilter?.percentChangeFrom?.toString() || '0')
        setNewPriceValue(savedRequestFilter?.priceFrom?.toString() || '0')
        setNewPriceToValue(savedRequestFilter?.priceTo?.toString() || '0')
        setNewAtrValue(savedRequestFilter?.atrFrom?.toString() || '0')
        setNewAtrToValue(savedRequestFilter?.atrTo?.toString() || '0')
        setNewPassedAtrFromValue(savedRequestFilter?.passedAtrFrom?.toString() || '0')
    }, [])

    useEffect(() => {
        setTotalFilters(
            Object.entries(requestFilter).filter(entry => entry[1] && entry[1] !== 0).length
        )
    }, [requestFilter])

    const resetQueryFilters = () => {
        setSearchParams({})
        setRequestFilter({})

        setNewVolume24hValue(DEFAULT_MIN_VOLUME)
        setNewVolume24hInUsdValue(DEFAULT_MIN_VOLUME)
        setNewVolumeValue(DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeValue(DEFAULT_MIN_VOLUME)
        setNewAvg10DayVolumeToValue('0')
        setNewPercentChangeFromValue('0')
        setNewPriceValue('0')
        setNewPriceToValue('0')
        setNewAtrValue('0')
        setNewAtrToValue('0')
        setNewPassedAtrFromValue('0')

        /*const updatedSettings = userSettingsMapper.updateUsaScreener(
            userSettings,
            {
            volume: toInt(DEFAULT_MIN_VOLUME),
            avg10DayVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            avg10DayVolumeToValue: 0,
            avg3MonthVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            percentChange: 0,
            priceFrom: 0,
            priceTo: 0,
            atrFrom: 0,
            atrTo: 0,
        })
        removeSelectedCryptoFuturesFiltersFromStorage()

        updateUserSettings(updatedSettings)*/
    }
    const applyQueryFilters = () => {
        const newRequestFilter: RequestFilterDto = {}
        newRequestFilter.volume24hFrom = toFloat(removeNumberDelimiter(newVolume24hValue))
        newRequestFilter.volume24hInUsdFrom = toFloat(removeNumberDelimiter(newVolume24hInUsd))
        newRequestFilter.volumeFrom = toFloat(removeNumberDelimiter(newVolumeValue))
        newRequestFilter.avg10DayVolumeFrom = toFloat(removeNumberDelimiter(newAvg10DayVolumeValue))
        newRequestFilter.avg10DayVolumeTo = toFloat(removeNumberDelimiter(newAvg10DayVolumeToValue))
        newRequestFilter.avg3MonthVolumeFrom = 0
        newRequestFilter.percentChangeFrom = toFloat(newPercentChangeFromValue)
        newRequestFilter.priceFrom = toFloat(newPriceValue)
        newRequestFilter.priceTo = toFloat(newPriceToValue)
        newRequestFilter.atrFrom = toFloat(newAtrValue)
        newRequestFilter.atrTo = toFloat(newAtrToValue)
        newRequestFilter.passedAtrFrom = toFloat(newPassedAtrFromValue)

        setRequestFilter(newRequestFilter)

        const existsFilters: {[key: string]: string} = {}

        searchParams.forEach((value, key) => {
            existsFilters[key] = value
        })
        existsFilters['page'] = '1'

        Object.entries(newRequestFilter).forEach(entry => {
            const key = entry[0]
            const value = entry[1]

            if (!value || value === 0) {
                delete existsFilters[key]
            } else {
                existsFilters[key] = value.toString()
            }
        })
        setSearchParams(existsFilters)

        /*const updatedSettings = userSettingsMapper.updateUsaScreener(
            userSettings,
            {
            volume: toInt(newVolumeValue),
            avg10DayVolumeValue: toInt(newAvg10DayVolumeValue),
            avg10DayVolumeToValue: toInt(newAvg10DayVolumeToValue),
            avg3MonthVolumeValue: toInt(DEFAULT_MIN_VOLUME),
            percentChange: toFloat(newPriceValue),
            priceFrom: toFloat(newPriceValue),
            priceTo: toFloat(newPriceToValue),
            atrFrom: toFloat(newAtrValue),
            atrTo: toFloat(newAtrToValue),
        })
        saveSelectedCryptoFuturesFiltersToStorage(newRequestFilter)

        updateUserSettings(updatedSettings)*/
    }
    const replaceNumberDelimiter = (value: string): string => {
        return value.replaceAll(',', '.')
    }
    const removeNumberDelimiter = (value: string): string => {
        return value.replaceAll('.', '')
    }
    const toNumberWithDotSeparator = (value: string): string => {
        return toFloat(value.replaceAll('.', '')).toLocaleString('de-DE')
    }
    return (
        <div>
            <button className="btn btn-sm btn-flex btn-light-primary menu-dropdown position-relative" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i className="bi bi-filter fs-5 me-1"></i>
                {t('content.controls.buttons.filter')}
                {totalFilters > 0 && (<span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-light-danger">{totalFilters}</span>)}
            </button>
            <div className="menu menu-sub menu-sub-dropdown w-650px w-md-650px" data-kt-menu="true" style={{zIndex: 107, position: "fixed", inset: "0px 0px auto auto", margin: 0, transform: "transform: translate3d(-612.5px, 221px, 0px)"}} data-popper-placement="bottom-end">
                <div className="px-7 py-5">
                    <div className="text-gray-700 fs-5 text-dark fw-bold">{t('content.filter.title')}</div>
                </div>
                <div className="separator border-gray-200"></div>
                    <div className='row gx-5 gx-xl-10'>
                        <div className='col-xxl-5 mb-5 mb-xl-10'>
                            <div className="px-7 py-5">
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.volume24hInUsd.title')}:
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '10ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newVolume24hInUsd}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewVolume24hInUsdValue(fixedVolume)
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.currentVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.currentVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '10ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newVolumeValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewVolumeValue(fixedVolume)
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.avg10DayVolume.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.avg10DayVolume.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '10ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newAvg10DayVolumeValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewAvg10DayVolumeValue(fixedVolume)
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{ m: 1, width: '8ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newAvg10DayVolumeToValue}
                                                onChange={(event) => {
                                                    const fixedVolume = toNumberWithDotSeparator(event.target.value)

                                                    setNewAvg10DayVolumeToValue(fixedVolume)
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">{t('content.filter.price.title')}:</label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '10ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPriceValue}
                                                onChange={(event) => {
                                                    setNewPriceValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{ m: 1, width: '10ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.to')}
                                                variant="standard"
                                                value={newPriceToValue}
                                                onChange={(event) => {
                                                    setNewPriceToValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputProps={{ inputProps: { min: 0.0, max: 40 } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-7 mb-5 mb-xl-10'>
                            <div className="px-7 py-5">
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.percentChange.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.percentChange.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '8ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPercentChangeFromValue}
                                                onChange={(event) => {
                                                    setNewPercentChangeFromValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label className="form-label fw-semibold">
                                        {t('content.filter.passedAtr.title')}:
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    {t('content.filter.passedAtr.hint')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className='bi bi-question-circle-fill mx-1'/>
                                        </OverlayTrigger>
                                    </label>
                                    <div className='d-flex'>
                                        <FormControl variant="standard" sx={{ m: 1, width: '8ch' }}>
                                            <TextField
                                                label={t('content.filter.common.labels.from')}
                                                variant="standard"
                                                value={newPassedAtrFromValue}
                                                onChange={(event) => {
                                                    setNewPassedAtrFromValue(replaceNumberDelimiter(event.target.value))
                                                }}
                                                sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                                                InputLabelProps={{
                                                    style: { color: (isDark ? '#999999' : '#555555') }
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="px-7 py-5">
                    <div className="d-flex justify-content-end">
                        <button type="button"
                                className="btn btn-sm btn-light btn-active-light-primary me-2"
                                data-kt-menu-dismiss="true"
                                onClick={resetQueryFilters}>
                            {t('content.common.controls.buttons.reset')}
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-primary"
                                data-kt-menu-dismiss="true"
                                onClick={applyQueryFilters}>
                            {t('content.common.controls.buttons.apply')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    CryptoScreenerFilter
}
