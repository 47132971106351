import {MarketUserSettingsDto, MiniChartSettingsDto, UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {ThemeModeType} from "../../../../_metronic/partials";

export class UserSettingsMapper {
    updateTheme(userSettings: UserSettingsDto | undefined, theme: ThemeModeType) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings || updatedSettings.settings === '{}') {
            updatedSettings.settings = {}
        }
        updatedSettings.settings.theme = theme

        return updatedSettings
    }

    updateLanguage(userSettings: UserSettingsDto | undefined, language: string) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings || updatedSettings.settings === '{}') {
            updatedSettings.settings = {}
        }
        updatedSettings.settings.language = language

        return updatedSettings
    }

    updatePaginationPageSize(userSettings: UserSettingsDto | undefined, pageSize: number) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.pagination) {
            updatedSettings.settings.pagination = {}
        }
        updatedSettings.settings.pagination.pageSize = pageSize

        return updatedSettings
    }

    updateUsaStocksMarket(userSettings: UserSettingsDto | undefined, marketSettings: MarketUserSettingsDto) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.markets) {
            updatedSettings.settings.markets = {}
        }
        updatedSettings.settings.markets.usaStocks = marketSettings

        return updatedSettings
    }

    updateUsaStocksMarketMiniChart(userSettings: UserSettingsDto | undefined, miniChartSettings: MiniChartSettingsDto) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings
                || !updatedSettings.settings.markets
                || !updatedSettings.settings.markets.usaStocks) {
            return updatedSettings
        }
        updatedSettings.settings.markets.usaStocks.miniChart = miniChartSettings

        return updatedSettings
    }

    updateCryptoFuturesMarket(userSettings: UserSettingsDto | undefined, marketSettings: MarketUserSettingsDto) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.markets) {
            updatedSettings.settings.markets = {}
        }
        updatedSettings.settings.markets.cryptoFutures = marketSettings

        return updatedSettings
    }

    updateForexMarket(userSettings: UserSettingsDto | undefined, marketSettings: MarketUserSettingsDto) {
        const updatedSettings: UserSettingsDto = userSettings || {}

        if (!updatedSettings.settings) {
            updatedSettings.settings = {}
        }

        if (!updatedSettings.settings.markets) {
            updatedSettings.settings.markets = {}
        }
        updatedSettings.settings.markets.forex = marketSettings

        return updatedSettings
    }
}
