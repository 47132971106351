import {createRoot} from "react-dom/client";
// Axios
import axios from "axios";
import {QueryClient, QueryClientProvider} from "react-query";
// Apps
import {MetronicI18nProvider} from "./_metronic/i18n/Metronici18n";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import {AppRoutes} from "./app/routing/AppRoutes";
import {AuthProvider, setupAxios} from "./app/modules/auth";
import moment from "moment";
import React from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import langEn from "./app/common/locale/en.json";
import langRu from "./app/common/locale/ru.json";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

export const toJson = (message: any) => {
    return JSON.stringify(message)
}

export const fromJson = (message: any) => {
    return JSON.parse(message)
}

const loggerLevelOrder: { [key: string]: number } = {
    DEBUG: 0,
    INFO: 1,
    WARN: 2,
    ERROR: 3,
}

export const isDev = (process.env.NODE_ENV === 'development')

const loggerMinLevel = (process.env.NODE_ENV === 'development' ? loggerLevelOrder.DEBUG : loggerLevelOrder.INFO)

const toLogMessage = (message: string, level: string) => {
    let levelOrder = loggerLevelOrder[level]

    if (loggerMinLevel > levelOrder) {
        return
    }
    const date = moment().format('YYYY-MM-DD HH:mm:ss')

    if (process.env.NODE_ENV === 'development') {
        console.log(`${date} ${level}  ${message}`)
    } else {
        console.log(
            toJson({
                '@timestamp': date,
                '@version': 1,
                level: level,
                message: message,
                application_name: process.env.REACT_APP_NAME
            })
        )
    }
}

const newLogger = () => {
    return {
        debug: (message: string) => {
            toLogMessage(message, 'DEBUG')
        },
        info: (message: string) => {
            toLogMessage(message, 'INFO')
        },
        error: (message: string) => {
            toLogMessage(message, 'ERROR')
        },
    }
}
export const logger = newLogger()

i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: langEn
            },
            ru: {
                translation: langRu
            },
        },
        lng: "ru",
        fallbackLng: 'ru',
    })

const queryClient = new QueryClient()
const container = document.getElementById('root')

if (container) {
    createRoot(container).render(
        <>
            <QueryClientProvider client={queryClient}>
                <MetronicI18nProvider>
                    <AuthProvider>
                        <AppRoutes/>
                    </AuthProvider>
                </MetronicI18nProvider>
            </QueryClientProvider>
        </>
    )
}
