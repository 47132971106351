import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {FC} from "react";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {saveLangToStorage} from "../../common/component/storage/LangStorage";

const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
        lang: 'ru',
        name: 'Русский',
        flag: toAbsoluteUrl('/media/flags/russia.svg'),
    },
]

const Lang: FC = () => {
    const { i18n } = useTranslation()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const currentLanguage = languages.find((x) => x.lang === i18n.language)

    const setLanguage = (lang: string) => {
        saveLangToStorage(lang)

        let newPathname = pathname

        i18n.languages.forEach(supportedLang => {
            newPathname = newPathname.replace(`/${supportedLang}`, '')
        })
        navigate("/" + lang + newPathname, {replace: true})
    }
    return (
        <div>
            <a href='#'
               className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px me-5'
               data-kt-menu-trigger="hover"
               data-kt-menu-placement='bottom-end'
               data-kt-menu-attach='parent'
            >
                <img
                    className='w-15px h-15px rounded-1'
                    src={currentLanguage?.flag}
                    alt={i18n.language}
                />
            </a>

            <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                 data-kt-menu='true'
            >
                {languages.map((lang) => (
                    <div className='menu-item px-3 my-0' key={lang.lang}>
                        <a className={clsx('menu-link px-3 py-2', {active: lang.lang === currentLanguage?.lang})}
                           onClick={() => setLanguage(lang.lang)}
                        >
                            <span className='symbol symbol-20px me-4'>
                                <img className='rounded-1' src={lang.flag} alt={i18n.language} />
                            </span>
                            {lang.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export {Lang}