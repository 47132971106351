import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {useThemeMode} from "../../../../_metronic/partials";
import {useSearchParams} from "react-router-dom";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {useTranslation} from "react-i18next";
import {RequestOrderType, RequestSortDto} from "../../../common/utils/filter/FilterUtils";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";

type Props = {
    requestSort: RequestSortDto
    setRequestSort: Dispatch<SetStateAction<RequestSortDto>>
    availableFields: AvailableSortFieldDto[]
    refetchScreenerData: () => void
}

export type AvailableSortFieldDto = {
    field: SortField
    label: string
}

export enum SortField {
    TICKER = 'TICKER',
    PERCENT_CHANGE = 'PERCENT_CHANGE',
    CURRENT_VOLUME = 'CURRENT_VOLUME',
    VOLUME_24H_IN_USD = 'VOLUME_24H_IN_USD',
    AVG_10_DAY_VOLUME = 'AVG_10_DAY_VOLUME',
    PRICE = 'PRICE',
    ATR = 'ATR',
}

export enum SortType {
    ASC = 'ASC',
    DESC = 'DESC',
}

const ScreenerSort: FC<Props> = (props) => {
    const {t} = useTranslation()
    const {isDark} = useThemeMode()
    const [searchParams, setSearchParams] = useSearchParams()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const {requestSort, setRequestSort, availableFields} = props
    const [sortFiled, setSortField] = useState(SortField.TICKER)
    const [sortType, setSortType] = useState(SortType.ASC)
    const [isSortExists, setSortExists] = useState(false)
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    useEffect(() => {
        if (userSettings.settings?.markets?.usaStocks?.sort?.field !== SortField.TICKER
                || userSettings.settings?.markets?.usaStocks?.sort?.order !== RequestOrderType.ASC) {
            setSortExists(true)
        } else {
            setSortExists(false)
        }
        setSortField((userSettings.settings?.markets?.usaStocks?.sort?.field as SortField) || SortField.TICKER)

        if (userSettings.settings?.markets?.usaStocks?.sort?.order === RequestOrderType.ASC) {
            setSortType(SortType.ASC)
        } else {
            setSortType(SortType.DESC)
        }
    }, [userSettings])

    const applySort = () => {
        const newRequestSort: RequestSortDto = {}
        newRequestSort.field = sortFiled
        newRequestSort.order = (sortType === SortType.ASC ? RequestOrderType.ASC : RequestOrderType.DESC)

        setRequestSort(newRequestSort)

        const existsFilters: {[key: string]: string} = {}

        searchParams.forEach((value, key) => {
            existsFilters[key] = value
        })

        if (newRequestSort.field) {
            existsFilters['sort-field'] = newRequestSort.field.toString().toLowerCase()
        } else {
            delete existsFilters['sort-field']
        }

        if (newRequestSort.order) {
            existsFilters['sort-order'] = newRequestSort.order.toString().toLowerCase()
        } else {
            delete existsFilters['sort-order']
        }
        setSearchParams(existsFilters)

        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.sort = newRequestSort

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
    }
    const resetSort = () => {
        setSearchParams({})
        setRequestSort({})
    }
    return (
        <div id="step-select-sort" className='me-3'>
            <button className="btn btn-sm btn-flex btn-light-primary menu-dropdown position-relative" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i className="bi bi-arrow-down-up fs-5 me-1"></i>
                {t('content.controls.buttons.sort')}
                {isSortExists && (<span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-light-danger">1</span>)}
            </button>
            <div className="menu menu-sub menu-sub-dropdown w-350px w-md-300px" data-kt-menu="true" style={{zIndex: 107, position: "fixed", inset: "0px 0px auto auto", margin: 0, transform: "transform: translate3d(-612.5px, 221px, 0px)"}} data-popper-placement="bottom-end">
                <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bold">{t('content.sort.title')}</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className="px-7 py-5">
                    <div className="mb-10">
                        <label className="form-label fw-semibold text-gray-700">{t('content.sort.type.title')}:</label>
                        <div className='d-flex'>
                        <FormControl
                            sx={{
                                input: { color: (isDark ? '#999999' : '#555555') },
                                color: (isDark ? '#999999' : '#555555'),
                                '.MuiSvgIcon-root ': {
                                    fill: (isDark ? '#999999' : '#555555') + " !important",
                                }
                            }}>
                            <RadioGroup
                                aria-labelledby="chart-order-field-group-label"
                                value={sortFiled}
                                onChange={event => setSortField(event.target.value as SortField)}
                            >
                                {availableFields.map(availableField => (
                                    <FormControlLabel key={`sort-field-${availableField.field}`} value={availableField.field} control={<Radio />} label={availableField.label} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        </div>
                    </div>
                    <div className="mb-10">
                        <label className="form-label fw-semibold text-gray-700">{t('content.sort.order.title')}:</label>
                        <div className='d-flex'>
                            <FormControl
                                sx={{
                                    input: { color: (isDark ? '#999999' : '#555555') },
                                    color: (isDark ? '#ddd' : '#555555'),
                                    '.MuiSvgIcon-root ': {
                                        fill: (isDark ? '#999999' : '#555555') + " !important",
                                    }
                                }}>
                                <RadioGroup
                                    aria-labelledby="chart-order-type-group-label"
                                    value={sortType}
                                    onChange={event => setSortType(event.target.value as SortType)}
                                >
                                    <FormControlLabel value={SortType.ASC} control={<Radio />} label={<i style={{color: '#fff'}} className='text-gray-700 bi-arrow-up'/>} />
                                    <FormControlLabel value={SortType.DESC} control={<Radio />} label={<i style={{color: '#fff'}} className='text-gray-700 bi-arrow-down'/>} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button"
                                className="btn btn-sm btn-light btn-active-light-primary me-2"
                                data-kt-menu-dismiss="true"
                                onClick={resetSort}>
                            {t('content.common.controls.buttons.reset')}
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-primary"
                                data-kt-menu-dismiss="true"
                                onClick={applySort}>
                            {t('content.common.controls.buttons.apply')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    ScreenerSort
}
