import React, {Suspense, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {I18nProvider} from "../_metronic/i18n/i18nProvider";
import {LayoutProvider, LayoutSplashScreen} from "../_metronic/layout/core";
import {MasterInit} from "../_metronic/layout/MasterInit";
import ReactGA from "react-ga4";
import {UserSettingsProvider} from "./common/provider/settings/UserSettingsDataProvider";

const App = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search + location.hash
    })
  }, [location.pathname + location.search + location.hash])

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <I18nProvider>
                <LayoutProvider>
                    <UserSettingsProvider>
                        <Outlet />
                        <MasterInit />
                    </UserSettingsProvider>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export {App}
