import {Box, InputAdornment, TextField} from "@mui/material";
import React, {Dispatch, FC, SetStateAction} from "react";
import {useThemeMode} from "../../../../_metronic/partials";

export type Props = {
    ticker: string | undefined
    setTicker: Dispatch<SetStateAction<string | undefined>>
    refetchDataFunction: () => void
}

export const SearchTickerComponent: FC<Props> = (props) => {
    const isDark = useThemeMode()
    const {ticker, setTicker, refetchDataFunction} = props

    const refetch = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()

            refetchDataFunction()
        }
    }
    return (
        <div className='d-flex align-items-center mx-5'>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: 160 },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    variant="standard"
                    value={ticker}
                    onChange={(event) => setTicker(event.target.value)}
                    onKeyDown={refetch}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    sx={{input: { color: (isDark ? '#999999' : '#555555'), borderBottom: "1px solid " + (isDark ? '#999999' : '#555555') } }}
                    InputLabelProps={{
                        style: { color: (isDark ? '#999999' : '#555555') }
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><i className="bi bi-search fs-4 text-gray-600"></i></InputAdornment>,
                    }}
                />
            </Box>
        </div>
    )
}
