/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {useLayout} from '../../core'
import {ThemeModeSwitcher} from "../../../partials";
import {useTranslation} from "react-i18next";
import {Lang} from "../../../../app/modules/lang/Lang";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {t} = useTranslation()

  return (
      <div
          id='kt_header'
          className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
          {...attributes.headerMenu}
      >
        <div
            className={clsx(
                classes.headerContainer.join(' '),
                'd-flex align-items-stretch justify-content-between'
            )}
        >
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            <div className='d-flex align-items-stretch'>
              <div className="app-navbar flex-shrink-0">
                <div className="app-navbar-item align-items-stretch ms-1 ms-md-4">
                  <div className="header-search d-flex align-items-stretch">
                    <div className="text-gray-700 d-flex align-items-center mt-8">
                      {t('content.header')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-stretch flex-shrink-0'>
              <div className='d-flex align-items-stretch flex-shrink-0'>
                <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                  <Lang />
                  <ThemeModeSwitcher
                      toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
