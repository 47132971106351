import {Ring} from "@uiball/loaders";
import React, {FC} from "react";

type Props = {
    message: string
}

const Overlay: FC<Props> = (props) => {
    const {message} = props

    return (
        <>
            <div className='modal fade show d-block'>
                <div className='modal-dialog modal-dialog-centered mw-500px'>
                    <div className='modal-content'>
                        <div className="modal-content">
                            <div className="modal-body overlay overlay-block align-content-center overlay-wrapper text-black mx-14">
                                <span className="mx-20 me-5"><Ring size={30} lineWeight={2} color="#555555"/></span>
                                <span className="text-gray-800">{message}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {Overlay}
