/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {PrivateRoutes} from "./PrivateRoutes";
import {App} from "../App";
import {useAuth} from "../modules/auth";
import ReactGA from "react-ga4";
import {logger} from "../../index";
import LanguagePath from "./LanguagePath";
import {useTranslation} from "react-i18next";
import {loadLangFromStorage} from "../common/component/storage/LangStorage";
import {Page404} from "../modules/errors/404/Page404";

const {PUBLIC_URL} = process.env
const DEFAULT_PATH = '/screeners/usa'

const AppRoutes: FC = () => {
    const { i18n } = useTranslation()
    const {currentUser} = useAuth()
    const googleGaId = process.env.REACT_APP_GOOGLE_GA_ID

    useEffect(() => {
        if (googleGaId) {
            logger.info('Google analytics initialized successfully')

            ReactGA.initialize(googleGaId)
        }
    }, [])

    const getDefaultLang = (): string => {
        return loadLangFromStorage() || i18n.options.fallbackLng
    }
    return (
        <>
            <BrowserRouter basename={`${PUBLIC_URL}`}>
                <Routes>
                    <Route element={<App/>}>
                        <Route path="/" element={<Navigate to={`/${getDefaultLang()}${DEFAULT_PATH}`}/>}/>
                        <Route path=":lang" element={<LanguagePath />}>
                            <Route path='*' element={<PrivateRoutes/>}/>
                        </Route>
                        <Route path="*" element={<Page404 />}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export {AppRoutes}
