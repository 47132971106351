import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ChartBarsType, ChartPeriod, ScreenerChart} from "../../../../common/component/chart/ScreenerChart";
import {UsaScreenerDto} from "../../../../common/dto/screener/UsaScreenerDto";
import {PaginationDto} from "../../../../common/dto/pagination/PaginationDto";
import {RequestFilterDto, RequestPaginationDto, RequestSortDto} from "../../../../common/utils/filter/FilterUtils";
import {
    getAllCryptoFuturesScreenerData,
    getAllCryptoFuturesTickers,
    getCryptoFuturesTickerScreenerChartDataByPeriod,
} from "../../../../common/request/screener/ScreenerRequest";
import {ToastContainer} from "react-toastify";
import {ThemeModeProvider, useThemeMode} from "../../../../../_metronic/partials";
import {ScreenerSort, SortField} from "../../component/ScreenerSort";
import {Pagination} from "../../../../common/component/pagination/Pagination";
import {Overlay} from "../../../../common/component/overlay/Overlay";
import {showErrorToastNotification} from "../../../../common/component/notification/ToastNotification";
import Swal from "sweetalert2";
import {CryptoScreenerFilter} from "../../component/CryptoScreenerFilter";
import {ScreenerExportComponent} from "../../component/ScreenerExportComponent";
import {
    loadSelectedCryptoFuturesTickersFromStorage,
    removeSelectedCryptoFuturesTickersFromStorage,
    saveSelectedCryptoFuturesTickersToStorage
} from "../../../../common/component/storage/TickersStorage";
import {loadSelectedCryptoFuturesFiltersFromStorage} from "../../../../common/component/storage/FiltersStorage";
import {DefaultTimeFrameComponent} from "../../component/DefaultTimeFrameComponent";
import {SearchTickerComponent} from "../../component/SearchTickerComponent";
import {SelectedTickersMinimizeButtonComponent} from "../../component/SelectedTickersMinimizeButtonComponent";
import {colorSchemes, ScreenerChartColorScheme} from "../../component/ScreenerChartColorScheme";

export type SelectedTickerDto = {
    ticker: string
    isLong: boolean
}

const CryptoFuturesScreenerPage = () => {
    const {t} = useTranslation()
    const {isDark} = useThemeMode()
    const hiddenChartContainerRef= useRef<HTMLDivElement | null>(null)
    const [barType, setBarType] = useState(ChartBarsType.OHLC)
    const [defaultTimeFrame, setDefaultTimeFrame] = useState(ChartPeriod.D1)
    const [showGrid, setShowGrid] = useState(false)
    const [spyForTickers, setSpyForTickers] = useState(false)
    const [defaultPageSize, setDefaultPageSize] = useState(50)
    const [screenerData, setScreenerData] = useState<UsaScreenerDto[]>([])
    const [pagination, setPagination] = useState<PaginationDto | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [isShowExportSelectedTickersModal, setShowExportSelectedTickersModal] = useState(false)
    const [selectedTickers, setSelectedTickers] = useState<SelectedTickerDto[]>([])
    const [exportedAllTickers, setExportedAllTickers] = useState<string[]>([])
    const [minimizeTickersModal, setMinimizeTickersModal] = useState(false)
    const [totalFilters, setTotalFilters] = useState(0)
    const [requestFilter, setRequestFilter] = useState<RequestFilterDto>(loadSelectedCryptoFuturesFiltersFromStorage())
    const [requestSort, setRequestSort] = useState<RequestSortDto>({})
    const [requestPagination, setRequestPagination] = useState<RequestPaginationDto>({})
    const [searchTicker, setSearchTicker] = useState<string | undefined>()
    const [chartColorScheme, setChartColorScheme] = useState(isDark ? colorSchemes.GREEN_RED.dark : colorSchemes.GREEN_RED.light)

    const refetchScreenerData = () => {
        setIsLoading(true)

        getAllCryptoFuturesScreenerData(defaultTimeFrame, searchTicker, requestFilter, requestSort, requestPagination).then(response => {
            const data = response?.data
                ?.map((value) => {
                    const attributes = value.attributes
                    attributes.pricePrecision = 8

                    return attributes
                }) || []

            setScreenerData(data)

            setPagination({
                maxSize: response?.meta?.page?.maxSize,
                total: response?.meta?.page?.total,
                prev: response?.meta?.page?.prev,
                next: response?.meta?.page?.next,
            })
            document.getElementById('root')?.scrollIntoView({ behavior: "smooth" })
        }).catch(error => {
            console.error(t('content.chart.error.request.candles'))

            setScreenerData([])

            showErrorToastNotification(t('content.chart.error.request.candles'))
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refetchScreenerData()
    }, [defaultTimeFrame, requestFilter, requestSort, requestPagination])

    useEffect(() => {
        setSelectedTickers(loadSelectedCryptoFuturesTickersFromStorage())
    }, [])

    const setCandlesBarType = () => {
        setBarType(ChartBarsType.CANDLES)
    }
    const setOhlcBarType = () => {
        setBarType(ChartBarsType.OHLC)
    }
    const exportAllFilteredTickers = () => {
        setIsLoading(true)

        getAllCryptoFuturesTickers(requestFilter).then(response => {
            setExportedAllTickers(response?.data?.attributes?.tickers || [])

            setShowExportSelectedTickersModal(true)
        }).catch(error => {
            console.error(t('content.chart.error.request.candles'))

            showErrorToastNotification(t('content.chart.error.request.candles'))
        }).finally(() => setIsLoading(false))
    }
    const removeSelectedTickers = () => {
        Swal.fire({
            html: t('content.resetTickers.confirmation.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: t('content.common.controls.buttons.yes'),
            cancelButtonText: t('content.common.controls.buttons.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                removeSelectedCryptoFuturesTickersFromStorage()

                setSelectedTickers([])
            }
        })
    }
    return (
        <div className=''>
            <ToastContainer />
            <div id='kt_content' className='d-flex flex-column flex-column-fluid'>
                <div id='kt_post' className='post d-flex flex-column-fluid'>
                    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain'>
                        <div id='kt_content_container' className="container-xxl">
                            <div className="app-main flex-column flex-row-fluid">
                                <div className='d-flex flex-column flex-column-fluid'>
                                    <div className="app-toolbar d-flex flex-stack py-4 py-lg-8">
                                        <div className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n6">
                                            <div className="d-flex justify-content-center flex-wrap">
                                                <button type="button"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (barType === ChartBarsType.OHLC ? ' active' : '')}
                                                        onClick={setOhlcBarType}
                                                >
                                                    <i className='bi bi-bar-chart fs-5'></i>
                                                    {t('content.controls.buttons.bars')}
                                                </button>
                                                <button type="button"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-10" + (barType === ChartBarsType.CANDLES ? ' active' : '')}
                                                        onClick={setCandlesBarType}
                                                >
                                                    <i className='bi bi-graph-up fs-5'></i>
                                                    {t('content.controls.buttons.candles')}
                                                </button>
                                                <button type="button"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (showGrid ? ' active' : '')}
                                                        onClick={() => setShowGrid(!showGrid)}
                                                >
                                                    <i className='bi bi-border-all fs-5'></i>
                                                    {t('content.controls.buttons.grid')}
                                                </button>
                                                <ScreenerChartColorScheme chartColorScheme={chartColorScheme}
                                                                          setChartColorScheme={setChartColorScheme}/>

                                                <DefaultTimeFrameComponent timeFrames={[ChartPeriod.D1, ChartPeriod.H4, ChartPeriod.H1]}
                                                                           defaultTimeFrame={defaultTimeFrame}

                                                                           setDefaultTimeFrame={setDefaultTimeFrame}/>
                                                <SearchTickerComponent ticker={searchTicker}
                                                                       setTicker={setSearchTicker}
                                                                       refetchDataFunction={refetchScreenerData}/>
                                            </div>
                                            <div className="d-flex align-items-center pt-4 pb-7 pt-lg-1 pb-lg-2">
                                                {/*<button type="button"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (spyForTickers ? ' active' : '')}
                                                        onClick={() => setSpyForTickers(!spyForTickers)}
                                                >
                                                    <i className='bi bi-eye fs-5'></i>
                                                    Spy
                                                </button>
                                                <ScreenerSavedFilterPreset refetchScreenerData={refetchScreenerData}/>*/}
                                                <ScreenerSort requestSort={requestSort}
                                                              setRequestSort={setRequestSort}
                                                              refetchScreenerData={refetchScreenerData}
                                                              availableFields={[
                                                                  {
                                                                      field: SortField.TICKER,
                                                                      label: t('content.sort.type.fields.ticker')
                                                                  },
                                                                  {
                                                                      field: SortField.VOLUME_24H_IN_USD,
                                                                      label: t('content.sort.type.fields.volume24hInUsd')
                                                                  },
                                                                  {
                                                                      field: SortField.PERCENT_CHANGE,
                                                                      label: t('content.sort.type.fields.percentChange')
                                                                  },
                                                                  {
                                                                      field: SortField.AVG_10_DAY_VOLUME,
                                                                      label: t('content.sort.type.fields.avg10DayVolume')
                                                                  },
                                                                  {
                                                                      field: SortField.PRICE,
                                                                      label: t('content.sort.type.fields.price')
                                                                  },
                                                                  {
                                                                      field: SortField.ATR,
                                                                      label: t('content.sort.type.fields.atr')
                                                                  }
                                                              ]}/>

                                                <CryptoScreenerFilter requestFilter={requestFilter}
                                                                      setRequestFilter={setRequestFilter}
                                                                      totalFilters={totalFilters}
                                                                      setTotalFilters={setTotalFilters}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app-toolbar d-flex flex-stack py-4">
                                        <div className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n6">
                                            <div className="d-flex justify-content-center flex-wrap">
                                                <div className='d-flex align-items-center'>
                                                    <span className="bullet bullet-dot bg-warning h-6px w-6px me-2 top-0 start-50 animation-blink"></span>
                                                    <span className="text-gray-600 flex-grow-1 pe-2">
                                                        {t('content.hints.dataUpdateInterval.crypto')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <button type="button"
                                                    className="btn btn-icon btn-light-primary me-5"
                                                    onClick={refetchScreenerData}
                                            >
                                                <i className='bi bi-arrow-repeat fs-2'></i>
                                            </button>
                                                {totalFilters > 0 && (
                                                    <button className="btn btn-sm btn-flex btn-light-info menu-dropdown position-relative"
                                                            onClick={exportAllFilteredTickers}>
                                                        <i className="bi bi-download fs-5 me-1"></i>
                                                        {t('content.controls.buttons.exportAllTickers')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app-content flex-column-fluid mb-10">
                                        <div className='mb-10'>
                                            <Pagination storageKey={'usa-screener'}
                                                        requestFilter={requestFilter}
                                                        requestPagination={requestPagination}
                                                        setRequestPagination={setRequestPagination}
                                                        pagination={pagination}
                                                        pageSizes={[25, 50, 100]}
                                                        defaultPageSize={defaultPageSize}
                                                        isLoading={isLoading}/>
                                        </div>
                                        <div>
                                            <div style={{width: '1440px', height: '800px', position: 'absolute', left: -10000, top: -10000}} ref={hiddenChartContainerRef}></div>
                                            {screenerData.map(data => (
                                                <ScreenerChart key={`screener-chart-${data.ticker}`}
                                                               showGrid={showGrid}
                                                               screenerData={data}
                                                               barType={barType}
                                                               chartColorScheme={chartColorScheme}
                                                               selectedTickers={selectedTickers}
                                                               setSelectedTickers={setSelectedTickers}
                                                               defaultSelectedChartPeriod={defaultTimeFrame}
                                                               hiddenChartContainerRef={hiddenChartContainerRef as any}
                                                               getTickerScreenerChartDataByPeriod={getCryptoFuturesTickerScreenerChartDataByPeriod}
                                                               availablePeriodButtons={[ChartPeriod.D1, ChartPeriod.H4, ChartPeriod.H1, ChartPeriod.M5]}
                                                               saveSelectedTickersToStorage={saveSelectedCryptoFuturesTickersToStorage}
                                                               isCryptoChart={true}/>
                                            ))}
                                        </div>
                                        <Pagination storageKey={'usa-screener-bottom'}
                                                    requestFilter={requestFilter}
                                                    requestPagination={requestPagination}
                                                    setRequestPagination={setRequestPagination}
                                                    pagination={pagination}
                                                    pageSizes={[25, 50, 100]}
                                                    defaultPageSize={defaultPageSize}
                                                    isLoading={isLoading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ScreenerExportComponent selectedTickers={selectedTickers}
                                                 minimizeTickersModal={minimizeTickersModal}
                                                 setShowExportSelectedTickersModal={setShowExportSelectedTickersModal}
                                                 setMinimizeTickersModal={setMinimizeTickersModal}
                                                 removeSelectedTickers={removeSelectedTickers}
                                                 exportedAllTickers={exportedAllTickers}
                                                 setExportedAllTickers={setExportedAllTickers}
                                                 isShowExportSelectedTickersModal={isShowExportSelectedTickersModal}
                                                 isCrypto={true}/>
                    </div>
                </div>
            </div>

            {selectedTickers.length > 0 && <SelectedTickersMinimizeButtonComponent setMinimizeTickersModal={setMinimizeTickersModal}
                                                                                   totalSelectedTickers={selectedTickers.length}/>}

            {isLoading && <Overlay message={"Awaiting data"}/>}
        </div>
    )
}

const CryptoFuturesScreener = () => {
    return (
        <ThemeModeProvider>
            <CryptoFuturesScreenerPage/>
        </ThemeModeProvider>
    )
}

export {
    CryptoFuturesScreener
}
