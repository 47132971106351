import {KTSVG} from "../../../../_metronic/helpers";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {SelectedTickerDto} from "../usa/UsaScreener";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

type Props = {
    selectedTickers: SelectedTickerDto[]
    exportedAllTickers: string[]
    setExportedAllTickers: Dispatch<SetStateAction<string[]>>
    setShowExportSelectedTickersModal: Dispatch<boolean>
    isCrypto: boolean
}

const ScreenerSelectedTickersExportModal: FC<Props> = (props) => {
    const {t} = useTranslation()
    const {selectedTickers, exportedAllTickers, setExportedAllTickers, setShowExportSelectedTickersModal, isCrypto} = props
    const [tickersDelimiter, setTickersDelimiter] = useState(' ')
    const [addCryptoSuffix, setAddCryptoSuffix] = useState(isCrypto)

    const copySelectedTickersToClipBoard = () => {
        setShowExportSelectedTickersModal(false)

        if (selectedTickers.length > 0) {
            navigator.clipboard.writeText(
                selectedTickers.map(selectedTicker => selectedTicker.ticker).join(tickersDelimiter)
            )
        } else {
            navigator.clipboard.writeText(exportedAllTickers.join(tickersDelimiter))
        }
        Swal.fire(
            t('content.exportSettings.confirmation.title'),
            t('content.exportSettings.confirmation.body'),
            'success'
        )
    }
    const changeAddCryptoSuffix = () => {
        setAddCryptoSuffix(prevState => !prevState)
    }
    const getTickerName = (selectedTicker: SelectedTickerDto) => {
        if (addCryptoSuffix) {
            return selectedTicker.ticker + ".P"
        }
        return selectedTicker.ticker
    }
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_trade'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-600px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='text-gray-700'>
                                {t('content.exportSettings.title')}
                            </h2>
                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                                onClick={() => setShowExportSelectedTickersModal(false)}
                            >
                                <span onClick={() => setExportedAllTickers([])}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </span>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* end::Modal title */}
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y'>
                            <div className="tab-content">
                                <div className="col-lg-10 fv-row fv-plugins-icon-container">
                                    <FormControl>
                                        <FormLabel id="delimiter-radio-buttons-group-label ">
                                            <span className='text-gray-700'>
                                                {t('content.exportSettings.delimiter.title')}:
                                            </span>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="delimiter-radio-buttons-group-label"
                                            defaultValue="DELIMITER_SPACE"
                                        >
                                            <span className='text-gray-700'><FormControlLabel value="DELIMITER_SPACE"
                                                                                              control={<Radio/>}
                                                                                              label={t('content.exportSettings.delimiter.space')}
                                                                                              onClick={() => setTickersDelimiter(' ')}/></span>
                                            <span className='text-gray-700'><FormControlLabel value="DELIMITER_COMMA"
                                                                                              control={<Radio/>}
                                                                                              label={t('content.exportSettings.delimiter.comma')}
                                                                                              onClick={() => setTickersDelimiter(', ')}/></span>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {isCrypto && (
                                    <div className="col-lg-10 fv-row fv-plugins-icon-container mt-6">
                                        <div className="form-check form-switch mt-2">
                                            <input className="form-check-input h-20px w-30px"
                                                   type="checkbox"
                                                   role="switch"
                                                   checked={addCryptoSuffix}
                                                   id="selected-tickers-add-suffix"
                                                   onChange={changeAddCryptoSuffix}/>
                                            <label className="form-check-label"
                                                   htmlFor="selected-tickers-add-suffix">
                                                {t('content.exportSettings.cryptoTickerSuffix')}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {selectedTickers.length > 0 && (
                                    <>
                                        <div
                                            className="text-gray-700 col-lg-10 fv-row fv-plugins-icon-container mt-10 mb-10">
                                            <h5 className="text-gray-700">LONG:</h5>
                                            {selectedTickers.filter(selectedTicker => selectedTicker.isLong).map(getTickerName).join(tickersDelimiter)}
                                        </div>
                                        <div className="text-gray-700 col-lg-10 fv-row fv-plugins-icon-container">
                                        <h5 className="text-gray-700">SHORT:</h5>
                                            {selectedTickers.filter(selectedTicker => !selectedTicker.isLong).map(getTickerName).join(tickersDelimiter)}
                                        </div>
                                    </>
                                )}
                                {(selectedTickers.length === 0 && exportedAllTickers.length > 0) && (
                                    <div
                                        className="text-gray-700 col-lg-10 fv-row fv-plugins-icon-container mt-10 mb-10">
                                        <span className='me-2'>{t('content.exportSettings.totalTickers.title')}:</span>
                                        <strong>{exportedAllTickers.length}</strong>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-12 fv-row fv-plugins-icon-container">
                                        <div className="d-flex justify-content-end">
                                            <a className="btn btn-sm btn-primary me-2 hover-scale"
                                               onClick={copySelectedTickersToClipBoard}>
                                                {t('content.common.controls.buttons.apply')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {
    ScreenerSelectedTickersExportModal,
}
