import React, {useEffect, useMemo, useRef, useState} from "react";
import {ChartBarsType, ChartPeriod, ScreenerChart} from "../../../common/component/chart/ScreenerChart";
import {UsaScreenerDto} from "../../../common/dto/screener/UsaScreenerDto";
import {ThemeModeProvider, useThemeMode} from "../../../../_metronic/partials";
import {Pagination} from "../../../common/component/pagination/Pagination";
import {PaginationDto} from "../../../common/dto/pagination/PaginationDto";
import {Overlay} from "../../../common/component/overlay/Overlay";
import {ScreenerFilter} from "../component/ScreenerFilter";
import {ToastContainer} from "react-toastify";
import {ScreenerSort, SortField} from "../component/ScreenerSort";
import {useTranslation} from "react-i18next";
import {RequestFilterDto, RequestPaginationDto, RequestSortDto} from "../../../common/utils/filter/FilterUtils";
import {
    getAllUsaScreenerData,
    getAllUsaTickers,
    getUsaSnP500ScreenerData,
    getUsaTickerScreenerChartDataByPeriod
} from "../../../common/request/screener/ScreenerRequest";
import {showErrorToastNotification} from "../../../common/component/notification/ToastNotification";
import Swal from "sweetalert2";
import {ScreenerExportComponent} from "../component/ScreenerExportComponent";
import {
    loadSelectedUsaTickersFromStorage,
    removeSelectedUsaTickersFromStorage,
    saveSelectedUsaTickersToStorage
} from "../../../common/component/storage/TickersStorage";
import {
    loadSelectedUsaFiltersFromStorage,
    loadSelectedUsaFiltersTemplateFromStorage
} from "../../../common/component/storage/FiltersStorage";
import {DefaultTimeFrameComponent} from "../component/DefaultTimeFrameComponent";
import {SearchTickerComponent} from "../component/SearchTickerComponent";
import {SelectedTickersMinimizeButtonComponent} from "../component/SelectedTickersMinimizeButtonComponent";
import {colorSchemes, ScreenerChartColorScheme} from "../component/ScreenerChartColorScheme";
import {ScreenerFilterTemplate} from "../component/ScreenerFilterTemplate";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {ScreenerMiniChart} from "../../../common/component/chart/ScreenerMiniChart";

export type SelectedTickerDto = {
    ticker: string
    isLong: boolean
}


const customStyle = {
    position: 'fixed',
    left: '100px',
    top: '100px',
    zIndex: 10000
}

const UsaScreenerPage = () => {
    const {t} = useTranslation()
    const {isDark} = useThemeMode()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const hiddenChartContainerRef= useRef<HTMLDivElement | null>(null)
    const [barType, setBarType] = useState(ChartBarsType.OHLC)
    const [showGrid, setShowGrid] = useState(false)
    const [spyForTickers, setSpyForTickers] = useState(false)
    const [defaultTimeFrame, setDefaultTimeFrame] = useState(ChartPeriod.D1)
    const [defaultPageSize, setDefaultPageSize] = useState(50)
    const [usaSnPScreenerData, setUsaSnPScreenerData] = useState<UsaScreenerDto>()
    const [screenerData, setScreenerData] = useState<UsaScreenerDto[]>([])
    const [pagination, setPagination] = useState<PaginationDto | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [isShowExportSelectedTickersModal, setShowExportSelectedTickersModal] = useState(false)
    const [selectedTickers, setSelectedTickers] = useState<SelectedTickerDto[]>([])
    const [exportedAllTickers, setExportedAllTickers] = useState<string[]>([])
    const [minimizeTickersModal, setMinimizeTickersModal] = useState(false)
    const [requestFilter, setRequestFilter] = useState<RequestFilterDto>(loadSelectedUsaFiltersFromStorage())
    const [existsFilterTemplates, setExistsFilterTemplates] = useState<{ [key: string]: any }>(loadSelectedUsaFiltersTemplateFromStorage())
    const [totalFilters, setTotalFilters] = useState(0)
    const [requestSort, setRequestSort] = useState<RequestSortDto>({})
    const [requestPagination, setRequestPagination] = useState<RequestPaginationDto>({})
    const [searchTicker, setSearchTicker] = useState<string | undefined>()
    const [newFilterTemplateName, setNewFilterTemplateName] = useState<string | undefined>()
    const [updateLoadedFilterTemplates, setUpdateLoadedFilterTemplates] = useState(false)
    const [chartColorScheme, setChartColorScheme] = useState(isDark ? colorSchemes.GREEN_RED.dark : colorSchemes.GREEN_RED.light)
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])
    const [joyRideSteps] = useState([
        {
            target: '#step-select-bars',
            content: 'This button switch all charts to bars',
        }, {
            target: '#step-select-candles',
            content: 'This button switch all charts to candles',
        }, {
            target: '#step-select-grid',
            content: 'This button show grid on all charts',
        }, {
            target: '#step-select-sort',
            content: 'Here you can set sorting field and direction',
        },
    ])
    useEffect(() => {
        if (userSettings.settings?.markets?.usaStocks) {
            setBarType(userSettings.settings?.markets?.usaStocks?.barType)
            setShowGrid(userSettings.settings?.markets?.usaStocks?.showGrid)
            setDefaultTimeFrame(userSettings.settings?.markets?.usaStocks?.defaultTimeFrame)
            setChartColorScheme(userSettings.settings?.markets?.usaStocks?.colorScheme)
        }
    }, [userSettings]);

    const refetchScreenerData = () => {
        setIsLoading(true)

        getAllUsaScreenerData(defaultTimeFrame, searchTicker, requestFilter, requestSort, requestPagination).then(response => {
            const data = response?.data
                ?.map((value) => {
                    const attributes = value.attributes
                    attributes.pricePrecision = 2

                    return attributes
                }) || []

            setScreenerData(data)

            setPagination({
                maxSize: response?.meta?.page?.maxSize,
                total: response?.meta?.page?.total,
                prev: response?.meta?.page?.prev,
                next: response?.meta?.page?.next,
            })
            document.getElementById('root')?.scrollIntoView({ behavior: "smooth" })
        }).catch(error => {
            console.error(t('content.chart.error.request.candles'))

            setScreenerData([])

            showErrorToastNotification(t('content.chart.error.request.candles'))
        }).finally(() => setIsLoading(false))
    }

    const refetchUsaSnP500ScreenerData = () => {
        getUsaSnP500ScreenerData().then(response => {
            const data = response?.data
                ?.map((value) => {
                    const attributes = value.attributes
                    attributes.pricePrecision = 2

                    return attributes
                }) || []

            if (!data || data.length < 1) {
                return
            }
            const maxCandles = 61;
            const snp500Screener = data[0]

            if (snp500Screener.quotes.length > maxCandles) {
                snp500Screener.quotes = snp500Screener.quotes.slice(snp500Screener.quotes.length - maxCandles, snp500Screener.quotes.length)
            }
            setUsaSnPScreenerData(snp500Screener)
        }).catch(error => {
            console.error(t('content.miniChart.error.request.candles'))

            setUsaSnPScreenerData(undefined)

            showErrorToastNotification(t('content.miniChart.error.request.candles'))
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (updateLoadedFilterTemplates) {
            console.log('Update')
            setUpdateLoadedFilterTemplates(false)
            setExistsFilterTemplates(loadSelectedUsaFiltersTemplateFromStorage())
        }
    }, [updateLoadedFilterTemplates]);

    useEffect(() => {
        refetchScreenerData()
        refetchUsaSnP500ScreenerData()
    }, [defaultTimeFrame, requestFilter, requestSort, requestPagination])

    useEffect(() => {
        setSelectedTickers(loadSelectedUsaTickersFromStorage())
    }, [])

    const setCandlesBarType = () => {
        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.barType = ChartBarsType.CANDLES

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
        setBarType(marketSettings!!.barType)
    }
    const setOhlcBarType = () => {
        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.barType = ChartBarsType.OHLC

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
        setBarType(marketSettings!!.barType)
    }
    const setUseGrid = () => {
        const newShowGrid = !showGrid
        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.showGrid = newShowGrid

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
        setShowGrid(newShowGrid)
    }
    const exportAllFilteredTickers = () => {
        setIsLoading(true)

        getAllUsaTickers(requestFilter).then(response => {
            setExportedAllTickers(response?.data?.attributes?.tickers || [])

            setShowExportSelectedTickersModal(true)
        }).catch(error => {
            console.error(t('content.chart.error.request.candles'))

            showErrorToastNotification(t('content.chart.error.request.candles'))
        }).finally(() => setIsLoading(false))
    }
    const removeSelectedTickers = () => {
        Swal.fire({
            html: t('content.resetTickers.confirmation.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: t('content.common.controls.buttons.yes'),
            cancelButtonText: t('content.common.controls.buttons.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                removeSelectedUsaTickersFromStorage()

                setSelectedTickers([])
            }
        })
    }
    const [drag, setDrag] = useState(userSettings.settings?.markets?.usaStocks?.miniChart || {
        opened: true,
        position: {
            x: 300,
            y: 100
        },
        size: {
            width: 700,
            height: 500
        }
    });
    const dragMiniChart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const mouseMoveHandler = (event: any) => {
            const newDrag = {
                opened: drag.opened,
                position: {
                    x: event.clientX - 16,
                    y: event.clientY - 16,
                },
                size: drag.size
            }
            setDrag(newDrag)

            updateUserSettings(
                userSettingsMapper.updateUsaStocksMarketMiniChart(userSettings, newDrag),
                () => {}
            )
        }
        const mouseUpHandler = () => {
            document.removeEventListener("mousemove", mouseMoveHandler)
            document.removeEventListener("mouseup", mouseUpHandler)
        }
        document.addEventListener("mousemove", mouseMoveHandler)
        document.addEventListener("mouseup", mouseUpHandler)
    }
    const miniChartRef = useRef(null);

    const resizeMiniChart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const x = event.clientX;
        const y = event.clientY;
        const initialWidth = parseInt( window.getComputedStyle(miniChartRef.current!!).width, 10);
        const initialHeight = parseInt( window.getComputedStyle(miniChartRef.current!!).height, 10);

        const mouseMoveHandler = (event: any) => {
            const newDrag = {
                opened: drag.opened,
                position: drag.position,
                size: {
                    width: initialWidth - x + event.clientX,
                    height: initialHeight - y + event.clientY,
                }
            }
            setDrag(newDrag)

            updateUserSettings(
                userSettingsMapper.updateUsaStocksMarketMiniChart(userSettings, newDrag),
                () => {}
            )
        }
        const mouseUpHandler = () => {
            document.removeEventListener("mousemove", mouseMoveHandler)
            document.removeEventListener("mouseup", mouseUpHandler)
        }
        document.addEventListener("mousemove", mouseMoveHandler)
        document.addEventListener("mouseup", mouseUpHandler)
    }
    const closeOpenSnP500MiniChart = () => {
        const newDrag = {
            opened: !drag.opened,
            position: drag.position,
            size: drag.size
        }
        setDrag(newDrag)

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarketMiniChart(userSettings, newDrag),
            () => {}
        )
    }
    return (
        <div>
            <ToastContainer />
            {/*<Joyride disableScrolling={true} steps={joyRideSteps}/>*/}
            <div id='kt_content' className='d-flex flex-column flex-column-fluid'>
                <div id='kt_post' className='post d-flex flex-column-fluid'>
                    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain'>
                        <div id='kt_content_container' className="container-xxl">
                            <div className="app-main flex-column flex-row-fluid">
                                <div className='d-flex flex-column flex-column-fluid'>
                                    <div className="app-toolbar d-flex flex-stack py-4 py-lg-8">
                                        <div className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n6">
                                            <div className="d-flex justify-content-center flex-wrap">
                                                <button type="button"
                                                        id="step-select-bars"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (barType === ChartBarsType.OHLC ? ' active' : '')}
                                                        onClick={setOhlcBarType}
                                                >
                                                    <i className='bi bi-bar-chart fs-5'></i>
                                                    {t('content.controls.buttons.bars')}
                                                </button>
                                                <button type="button"
                                                        id='step-select-candles'
                                                        className={"btn btn-sm btn-flex btn-light-primary me-10" + (barType === ChartBarsType.CANDLES ? ' active' : '')}
                                                        onClick={setCandlesBarType}
                                                >
                                                    <i className='bi bi-graph-up fs-5'></i>
                                                    {t('content.controls.buttons.candles')}
                                                </button>
                                                <button type="button"
                                                        id='step-select-grid'
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (showGrid ? ' active' : '')}
                                                        onClick={setUseGrid}
                                                >
                                                    <i className='bi bi-border-all fs-5'></i>
                                                    {t('content.controls.buttons.grid')}
                                                </button>
                                                <ScreenerChartColorScheme chartColorScheme={chartColorScheme}
                                                                          setChartColorScheme={setChartColorScheme}/>

                                                <DefaultTimeFrameComponent timeFrames={[ChartPeriod.W, ChartPeriod.D1, ChartPeriod.H1]}
                                                                           defaultTimeFrame={defaultTimeFrame}
                                                                           setDefaultTimeFrame={setDefaultTimeFrame}/>

                                                <SearchTickerComponent ticker={searchTicker}
                                                                       setTicker={setSearchTicker}
                                                                       refetchDataFunction={refetchScreenerData}/>
                                            </div>
                                            <div className="d-flex align-items-center pt-4 pb-7 pt-lg-1 pb-lg-2">
                                                {/*<button type="button"
                                                        className={"btn btn-sm btn-flex btn-light-primary me-5" + (spyForTickers ? ' active' : '')}
                                                        onClick={() => setSpyForTickers(!spyForTickers)}
                                                >
                                                    <i className='bi bi-eye fs-5'></i>
                                                    Spy
                                                </button>
                                                <ScreenerSavedFilterPreset refetchScreenerData={refetchScreenerData}/>*/}
                                                <ScreenerSort requestSort={requestSort}
                                                              setRequestSort={setRequestSort}
                                                              refetchScreenerData={refetchScreenerData}
                                                              availableFields={[
                                                                  {
                                                                      field: SortField.TICKER,
                                                                      label: t('content.sort.type.fields.ticker')
                                                                  },
                                                                  {
                                                                      field: SortField.PERCENT_CHANGE,
                                                                      label: t('content.sort.type.fields.percentChange')
                                                                  },
                                                                  {
                                                                      field: SortField.CURRENT_VOLUME,
                                                                      label: t('content.sort.type.fields.currentVolume')
                                                                  },
                                                                  {
                                                                      field: SortField.AVG_10_DAY_VOLUME,
                                                                      label: t('content.sort.type.fields.avg10DayVolume')
                                                                  },
                                                                  {
                                                                      field: SortField.PRICE,
                                                                      label: t('content.sort.type.fields.price')
                                                                  },
                                                                  {
                                                                      field: SortField.ATR,
                                                                      label: t('content.sort.type.fields.atr')
                                                                  }
                                                              ]}/>
                                                <ScreenerFilter requestFilter={requestFilter}
                                                                setRequestFilter={setRequestFilter}
                                                                newFilterTemplateName={newFilterTemplateName}
                                                                setNewFilterTemplateName={setNewFilterTemplateName}
                                                                totalFilters={totalFilters}
                                                                setTotalFilters={setTotalFilters}
                                                                setUpdateLoadedFilterTemplates={setUpdateLoadedFilterTemplates}/>

                                                <ScreenerFilterTemplate setUpdateLoadedFilterTemplates={setUpdateLoadedFilterTemplates}
                                                                        existsFilterTemplates={existsFilterTemplates}
                                                                        setRequestFilter={setRequestFilter}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app-toolbar d-flex flex-stack py-4">
                                        <div className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n6">
                                            <div className="d-flex justify-content-center flex-wrap">
                                                <div className='d-flex align-items-center'>
                                                    <span
                                                        className="bullet bullet-dot bg-warning h-6px w-6px me-2 top-0 start-50 animation-blink"></span>
                                                    <span className="text-gray-600 flex-grow-1 pe-2">
                                                        {t('content.hints.dataUpdateInterval.usa')}
                                                        {/*15 minutes delayed data*/}
                                                    </span>
                                                </div>
                                            </div>
                                            {totalFilters > 0 && (
                                                <div className="d-flex align-items-center">
                                                    <button className={"btn btn-sm btn-flex btn-light-primary menu-dropdown position-relative ms-5" + (drag.opened ? " active" : "")}
                                                            onClick={closeOpenSnP500MiniChart}>
                                                        <i className="bi bi-graph-up fs-5 me-1"></i>
                                                        {t('content.controls.buttons.openSnP500MiniChart')}
                                                    </button>
                                                    <button className="btn btn-sm btn-flex btn-light-info menu-dropdown position-relative"
                                                            onClick={exportAllFilteredTickers}>
                                                        <i className="bi bi-download fs-5 me-1"></i>
                                                        {t('content.controls.buttons.exportAllTickers')}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="app-content flex-column-fluid mb-10">
                                        <div className='mb-10'>
                                            <Pagination storageKey={'usa-screener'}
                                                        requestFilter={requestFilter}
                                                        requestPagination={requestPagination}
                                                        setRequestPagination={setRequestPagination}
                                                        pagination={pagination}
                                                        pageSizes={[25, 50, 100]}
                                                        defaultPageSize={defaultPageSize}
                                                        isLoading={isLoading}/>
                                        </div>
                                        {(drag.opened && usaSnPScreenerData) && (
                                            <div style={{
                                                position: "fixed",
                                                zIndex: 10000,
                                                left: `${drag.position.x}px`,
                                                top: `${drag.position.y}px`
                                            }}
                                                 ref={miniChartRef}
                                            >
                                                <button
                                                    className={"btn btn-icon btn-light-primary btn-sm me-3"}
                                                    onMouseDown={dragMiniChart}
                                                >
                                                    <i className='bi bi-arrows-move fs-2'/>
                                                </button>

                                                <button className={"btn btn-icon btn-light-primary btn-sm me-3"}
                                                        onClick={closeOpenSnP500MiniChart}>
                                                    <i className='bi bi-x-square fs-2'/>
                                                </button>

                                                <ScreenerMiniChart key={`usa-snp500-screener-chart`}
                                                                   chartWidth={drag.size?.width || 700}
                                                                   chartHeight={drag.size?.height || 500}
                                                                   showGrid={showGrid}
                                                                   screenerData={usaSnPScreenerData}
                                                                   barType={barType}
                                                                   chartColorScheme={chartColorScheme}
                                                                   selectedTickers={selectedTickers}
                                                                   setSelectedTickers={setSelectedTickers}
                                                                   defaultSelectedChartPeriod={defaultTimeFrame}
                                                                   hiddenChartContainerRef={hiddenChartContainerRef as any}
                                                                   getTickerScreenerChartDataByPeriod={getUsaTickerScreenerChartDataByPeriod}
                                                                   availablePeriodButtons={[ChartPeriod.W, ChartPeriod.D1, ChartPeriod.H1, ChartPeriod.M5]}
                                                                   saveSelectedTickersToStorage={() => {
                                                                   }}
                                                                   isCryptoChart={false}/>

                                                <div className="app-toolbar d-flex flex-stack py-4">
                                                    <div className="d-flex flex-grow-1 flex-stack flex-wrap gap-2 mb-n6">
                                                        <div className="d-flex justify-content-center flex-wrap">
                                                            <div className='d-flex align-items-center'>

                                                            </div>
                                                        </div>
                                                        <button className={"btn btn-icon btn-light-primary btn-sm me-3"}
                                                                onMouseDown={resizeMiniChart}>
                                                            <i className='bi bi-arrows-angle-contract fs-2'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <div style={{
                                                width: 1440,
                                                height: 800,
                                                position: 'absolute',
                                                left: -10000,
                                                top: -10000
                                            }} ref={hiddenChartContainerRef}>

                                            </div>
                                            {screenerData.map(data => (
                                                <ScreenerChart key={`screener-chart-${data.ticker}`}
                                                               showGrid={showGrid}
                                                               screenerData={data}
                                                               barType={barType}
                                                               chartColorScheme={chartColorScheme}
                                                               selectedTickers={selectedTickers}
                                                               setSelectedTickers={setSelectedTickers}
                                                               defaultSelectedChartPeriod={defaultTimeFrame}
                                                               hiddenChartContainerRef={hiddenChartContainerRef as any}
                                                               getTickerScreenerChartDataByPeriod={getUsaTickerScreenerChartDataByPeriod}
                                                               availablePeriodButtons={[ChartPeriod.W, ChartPeriod.D1, ChartPeriod.H1, ChartPeriod.M5]}
                                                               saveSelectedTickersToStorage={saveSelectedUsaTickersToStorage}
                                                               isCryptoChart={false}/>
                                            ))}
                                        </div>
                                        <Pagination storageKey={'usa-screener-bottom'}
                                                    requestFilter={requestFilter}
                                                    requestPagination={requestPagination}
                                                    setRequestPagination={setRequestPagination}
                                                    pagination={pagination}
                                                    pageSizes={[25, 50, 100]}
                                                    defaultPageSize={defaultPageSize}
                                                    isLoading={isLoading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ScreenerExportComponent selectedTickers={selectedTickers}
                                                 minimizeTickersModal={minimizeTickersModal}
                                                 setShowExportSelectedTickersModal={setShowExportSelectedTickersModal}
                                                 setMinimizeTickersModal={setMinimizeTickersModal}
                                                 removeSelectedTickers={removeSelectedTickers}
                                                 exportedAllTickers={exportedAllTickers}
                                                 setExportedAllTickers={setExportedAllTickers}
                                                 isShowExportSelectedTickersModal={isShowExportSelectedTickersModal}
                                                 isCrypto={false}/>
                    </div>
                </div>
            </div>
            {selectedTickers.length > 0 && <SelectedTickersMinimizeButtonComponent setMinimizeTickersModal={setMinimizeTickersModal}
                                                                                   totalSelectedTickers={selectedTickers.length}/>}

            {isLoading && <Overlay message={"Awaiting data"}/>}
        </div>
    )
}

const UsaScreener = () => {
    return (
        <ThemeModeProvider>
            <UsaScreenerPage/>
        </ThemeModeProvider>
    )
}

export {
    UsaScreener
}
