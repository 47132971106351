import React, {Dispatch, FC, SetStateAction} from "react";

type Props = {
    setMinimizeTickersModal: Dispatch<SetStateAction<boolean>>
    totalSelectedTickers: number
}

const SelectedTickersMinimizeButtonComponent: FC<Props> = (props) => {
    const {setMinimizeTickersModal, totalSelectedTickers} = props

    return (
        <div style={{position: 'fixed', bottom: '50%', right: 30}}
             className='btn btn-icon w-auto px-0 aside-toggle active'
             onClick={() => setMinimizeTickersModal(false)}
        >
        <span className="rotate-180">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="mh-50px"><path opacity="0.5"
                                           d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
                                           fill="currentColor"></path><path
                d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 15.6343 11.4343Z"
                fill="currentColor"></path></svg>
        </span>
            {totalSelectedTickers > 0 && (
                <span className="badge badge-circle badge-outline badge-primary mt-1">{totalSelectedTickers}</span>
            )}
        </div>
    )
}

export {
    SelectedTickersMinimizeButtonComponent
}
