/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import {faBitcoinSign, faMoneyBillTrendUp} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub";

export function AsideMenuMain() {
    const {t} = useTranslation()
    const { i18n } = useTranslation()

    return (
        <>
            <AsideMenuItem
                to={`/${i18n.language}/screeners/usa`}
                icon={faMoneyBillTrendUp}
                title={t('asideMenu.usaStocks')}
                fontIcon='bi-app-indicator'
            />
            {/*<AsideMenuItem
                to={`/${i18n.language}/screeners/forex`}
                icon={faMoneyBillTrendUp}
                title={t('asideMenu.forex')}
                fontIcon='bi-app-indicator'
            />*/}
            <AsideMenuItemWithSub to={`/${i18n.language}/screeners/crypto/futures`}
                                  title={t('asideMenu.crypto.main')}
                                  icon={faBitcoinSign}>
                <AsideMenuItem to={`/${i18n.language}/screeners/crypto/futures`} title={t('asideMenu.crypto.futures')} hasBullet={true} />
                {/*<AsideMenuItem to={`/${i18n.language}/screeners/crypto/spot`} title={t('asideMenu.crypto.spot')} hasBullet={true} />*/}
            </AsideMenuItemWithSub>
        </>
    )
}
