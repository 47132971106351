import {UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {toJson} from "../../../../index";

const API_URL = process.env.REACT_APP_API_URL
const USER_SETTINGS_STORAGE_KEY = 'user-settings'

const getUserSettings = (): UserSettingsDto | null => {
    const data = localStorage.getItem(USER_SETTINGS_STORAGE_KEY)

    if (!data) {
        return null
    }
    return JSON.parse(data)
}

const updateUserSettings = (userSettings: UserSettingsDto | undefined, refetchUserSettingsDataCallback: any) => {
    if (userSettings) {
        localStorage.setItem(USER_SETTINGS_STORAGE_KEY, toJson(userSettings))

        refetchUserSettingsDataCallback()
    }
}

export {
    getUserSettings,
    updateUserSettings,
}