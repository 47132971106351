import {toJson} from "../../../../index";
import {SelectedTickerDto} from "../../../modules/screener/usa/UsaScreener";

const USA_TICKERS_STORAGE_KEY = 'usa-screener-tickers'
const CRYPTO_FUTURES_TICKERS_STORAGE_KEY = 'crypto-futures-screener-tickers'
const CRYPTO_SPOT_TICKERS_STORAGE_KEY = 'crypto-spot-screener-tickers'
const FOREX_TICKERS_STORAGE_KEY = 'forex-screener-tickers'

const saveSelectedUsaTickersToStorage = (tickers: SelectedTickerDto[]) => {
    localStorage.setItem(USA_TICKERS_STORAGE_KEY, toJson(tickers))
}

const loadSelectedUsaTickersFromStorage = (): SelectedTickerDto[] => {
    const tickers = localStorage.getItem(USA_TICKERS_STORAGE_KEY)

    if (!tickers) {
        return []
    }
    return JSON.parse(tickers)
}

const removeSelectedUsaTickersFromStorage = () => {
    localStorage.removeItem(USA_TICKERS_STORAGE_KEY)
}

const saveSelectedCryptoFuturesTickersToStorage = (tickers: SelectedTickerDto[]) => {
    localStorage.setItem(CRYPTO_FUTURES_TICKERS_STORAGE_KEY, toJson(tickers))
}

const loadSelectedCryptoFuturesTickersFromStorage = (): SelectedTickerDto[] => {
    const tickers = localStorage.getItem(CRYPTO_FUTURES_TICKERS_STORAGE_KEY)

    if (!tickers) {
        return []
    }
    return JSON.parse(tickers)
}

const removeSelectedCryptoFuturesTickersFromStorage = () => {
    localStorage.removeItem(CRYPTO_FUTURES_TICKERS_STORAGE_KEY)
}

const saveSelectedCryptoSpotTickersToStorage = (tickers: SelectedTickerDto[]) => {
    localStorage.setItem(CRYPTO_SPOT_TICKERS_STORAGE_KEY, toJson(tickers))
}

const loadSelectedCryptoSpotTickersFromStorage = (): SelectedTickerDto[] => {
    const tickers = localStorage.getItem(CRYPTO_SPOT_TICKERS_STORAGE_KEY)

    if (!tickers) {
        return []
    }
    return JSON.parse(tickers)
}

const removeSelectedCryptoSpotTickersFromStorage = () => {
    localStorage.removeItem(CRYPTO_SPOT_TICKERS_STORAGE_KEY)
}

const saveSelectedForexTickersToStorage = (tickers: SelectedTickerDto[]) => {
    localStorage.setItem(FOREX_TICKERS_STORAGE_KEY, toJson(tickers))
}

const loadSelectedForexFromStorage = (): SelectedTickerDto[] => {
    const tickers = localStorage.getItem(FOREX_TICKERS_STORAGE_KEY)

    if (!tickers) {
        return []
    }
    return JSON.parse(tickers)
}

const removeSelectedForexTickersFromStorage = () => {
    localStorage.removeItem(FOREX_TICKERS_STORAGE_KEY)
}

export {
    saveSelectedUsaTickersToStorage,
    loadSelectedUsaTickersFromStorage,
    removeSelectedUsaTickersFromStorage,
    saveSelectedCryptoFuturesTickersToStorage,
    loadSelectedCryptoFuturesTickersFromStorage,
    removeSelectedCryptoFuturesTickersFromStorage,
    saveSelectedCryptoSpotTickersToStorage,
    loadSelectedCryptoSpotTickersFromStorage,
    removeSelectedCryptoSpotTickersFromStorage,
    saveSelectedForexTickersToStorage,
    loadSelectedForexFromStorage,
    removeSelectedForexTickersFromStorage,
}
