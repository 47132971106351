import {Theme, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const showSuccessToastNotification = (message: string, theme: Theme = "light") => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    })
}

const showErrorToastNotification = (message: string, theme: Theme = "light") => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    })
}

export {
    showSuccessToastNotification,
    showErrorToastNotification
}