import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {useThemeMode} from "../../../../_metronic/partials";
import {useTranslation} from "react-i18next";
import {logger} from "../../../../index";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";

export type ChartColorScheme = {
    id: ColorScheme
    theme: ThemeMode
    upColor: string
    downColor: string
}

export enum ColorScheme {
    BLUE_GRAY = 'BLUE_GRAY',
    GREEN_RED = 'GREEN_RED',
}

export enum ThemeMode {
    DARK = 'DARK',
    LIGHT = 'LIGHT',
    SYSTEM = 'SYSTEM',
}

export const colorSchemes = {
    BLUE_GRAY: {
        light: {
            id: ColorScheme.BLUE_GRAY,
            theme: ThemeMode.LIGHT,
            upColor: '#1475cf',
            downColor: '#787878'
        },
        dark: {
            id: ColorScheme.BLUE_GRAY,
            theme: ThemeMode.DARK,
            upColor: '#1783e6',
            downColor: '#e1e0e0'
        }
    },
    GREEN_RED: {
        light: {
            id: ColorScheme.GREEN_RED,
            theme: ThemeMode.LIGHT,
            upColor: '#26a69a',
            downColor: '#ef5350'
        },
        dark: {
            id: ColorScheme.GREEN_RED,
            theme: ThemeMode.DARK,
            upColor: '#26a69a',
            downColor: '#ef5350'
        }
    }
}

type Props = {
    chartColorScheme: ChartColorScheme
    setChartColorScheme: Dispatch<SetStateAction<ChartColorScheme>>
}

const ScreenerChartColorScheme: FC<Props> = (props) => {
    const {isDark} = useThemeMode()
    const {t} = useTranslation()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const {chartColorScheme, setChartColorScheme} = props
    const [colorScheme, setColorScheme] = useState(ColorScheme.GREEN_RED)
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    useEffect(() => {
        if (userSettings.settings?.markets?.usaStocks) {
            setChartColorScheme(userSettings.settings?.markets?.usaStocks?.colorScheme)
            setColorScheme(userSettings.settings?.markets?.usaStocks?.colorScheme?.id)
        }
    }, []);

    const applyChartColor = () => {
        let newColorScheme = colorSchemes.GREEN_RED.dark

        switch (colorScheme) {
            case ColorScheme.BLUE_GRAY:
                if (isDark) {
                    newColorScheme = colorSchemes.BLUE_GRAY.dark
                } else {
                    newColorScheme = colorSchemes.BLUE_GRAY.light
                }
                break;
            case ColorScheme.GREEN_RED:
                if (isDark) {
                    newColorScheme = colorSchemes.GREEN_RED.dark
                } else {
                    newColorScheme = colorSchemes.GREEN_RED.light
                }
                break;
            default:
                logger.error(`Could not set chart color scheme! Reason: color scheme: ${colorScheme}`)
                return
        }
        setChartColorScheme(newColorScheme)

        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.colorScheme = newColorScheme

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
    }
    const resetChartColor = () => {
        if (isDark) {
            setChartColorScheme(colorSchemes.GREEN_RED.dark)
        } else {
            setChartColorScheme(colorSchemes.GREEN_RED.light)
        }
    }
    const getColorSchemeByTheme = (colorScheme: any): ChartColorScheme => {
        if (isDark) {
            return colorScheme.dark
        }
        return colorScheme.light
    }
    return (
        <div id="step-select-sort" className='me-8'>
            <button className="btn btn-flex btn-light-primary menu-dropdown position-relative" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i className="bi bi-eyedropper fs-5 me-1"></i>
                {t('content.controls.buttons.chartColorScheme')}
            </button>
            <div className="menu menu-sub menu-sub-dropdown w-350px w-md-300px" data-kt-menu="true" style={{zIndex: 107, position: "fixed", inset: "0px 0px auto auto", margin: 0, transform: "transform: translate3d(-612.5px, 221px, 0px)"}} data-popper-placement="bottom-end">
                <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bold">{t('content.chartColorScheme.title')}</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className="px-7 py-5">
                    <div className="mb-10">
                        <div className='d-flex'>
                            <FormControl
                                sx={{
                                    input: { color: (isDark ? '#999999' : '#555555') },
                                    color: (isDark ? '#999999' : '#555555'),
                                    '.MuiSvgIcon-root ': {
                                        fill: (isDark ? '#999999' : '#555555') + " !important",
                                    }
                                }}>
                                <RadioGroup
                                    aria-labelledby="chart-order-field-group-label"
                                    value={colorScheme}
                                    onChange={event => setColorScheme(event.target.value as ColorScheme)}
                                >
                                    <FormControlLabel key={`chart-color-scheme-field-blue-gray`} value='BLUE_GRAY' control={<Radio />} label={
                                        <div className='mt-1'>
                                            <div style={{
                                                display: 'inline-block',
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor: getColorSchemeByTheme(colorSchemes.BLUE_GRAY).upColor
                                            }}></div>
                                            <span className='mx-3'>UP</span>
                                            <div style={{
                                                display: 'inline-block',
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor: getColorSchemeByTheme(colorSchemes.BLUE_GRAY).downColor
                                            }}></div>
                                            <span className='mx-3'>DOWN</span>
                                        </div>
                                    }/>
                                    <FormControlLabel key={`chart-color-scheme-field-green-red`} value='GREEN_RED' control={<Radio />} label={
                                        <div className='mt-2'>
                                            <div style={{
                                                display: 'inline-block',
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor: getColorSchemeByTheme(colorSchemes.GREEN_RED).upColor
                                            }}></div>
                                            <span className='mx-3'>UP</span>
                                            <div style={{
                                                display: 'inline-block',
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor: getColorSchemeByTheme(colorSchemes.GREEN_RED).downColor
                                            }}></div>
                                            <span className='mx-3'>DOWN</span>
                                        </div>
                                    }/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type="button"
                                className="btn btn-sm btn-light btn-active-light-primary me-2"
                                data-kt-menu-dismiss="true"
                                onClick={resetChartColor}>
                            {t('content.common.controls.buttons.reset')}
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-primary"
                                data-kt-menu-dismiss="true"
                                onClick={applyChartColor}>
                            {t('content.common.controls.buttons.apply')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    ScreenerChartColorScheme
}
