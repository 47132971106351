import {toJson} from "../../../../index";
import {RequestFilterDto} from "../../utils/filter/FilterUtils";

const md5 = require('md5');

const USA_FILTERS_STORAGE_KEY = 'usa-screener-filters'
const USA_FILTER_TEMPLATES_STORAGE_KEY = 'usa-screener-filter-templates'
const CRYPTO_FUTURES_FILTERS_STORAGE_KEY = 'crypto-futures-screener-filters'
const CRYPTO_SPOT_FILTERS_STORAGE_KEY = 'crypto-spot-screener-filters'
const FOREX_FILTERS_STORAGE_KEY = 'forex-screener-filters'

const saveSelectedUsaFiltersToStorage = (filter: RequestFilterDto) => {
    localStorage.setItem(USA_FILTERS_STORAGE_KEY, toJson(filter))
}

const loadSelectedUsaFiltersFromStorage = (): RequestFilterDto => {
    const filter = localStorage.getItem(USA_FILTERS_STORAGE_KEY)

    if (!filter) {
        return {}
    }
    return JSON.parse(filter)
}

const saveSelectedUsaFiltersTemplateToStorage = (templateName: string, filter: RequestFilterDto) => {
    const hashKey = md5(templateName)
    const data: any = loadSelectedUsaFiltersTemplateFromStorage()

    data[hashKey] = {
        name: templateName,
        filter: filter
    }
    localStorage.setItem(USA_FILTER_TEMPLATES_STORAGE_KEY, toJson(data))
}

const loadSelectedUsaFiltersTemplateFromStorage = (): {[key: string]: any} => {
    const filter = localStorage.getItem(USA_FILTER_TEMPLATES_STORAGE_KEY)

    if (!filter) {
        return {}
    }
    return JSON.parse(filter)
}

const removeSelectedUsaFiltersFromStorage = () => {
    localStorage.removeItem(USA_FILTERS_STORAGE_KEY)
}

const removeSelectedUsaFilterTemplateFromStorage = (hashKey: string) => {
    const data: any = loadSelectedUsaFiltersTemplateFromStorage()

    delete data[hashKey]

    localStorage.setItem(USA_FILTER_TEMPLATES_STORAGE_KEY, toJson(data))
}

const saveSelectedCryptoFuturesFiltersToStorage = (filter: RequestFilterDto) => {
    localStorage.setItem(CRYPTO_FUTURES_FILTERS_STORAGE_KEY, toJson(filter))
}

const loadSelectedCryptoFuturesFiltersFromStorage = (): RequestFilterDto => {
    const filter = localStorage.getItem(CRYPTO_FUTURES_FILTERS_STORAGE_KEY)

    if (!filter) {
        return {}
    }
    return JSON.parse(filter)
}

const removeSelectedCryptoFuturesFiltersFromStorage = () => {
    localStorage.removeItem(CRYPTO_FUTURES_FILTERS_STORAGE_KEY)
}

const saveSelectedCryptoSpotFiltersToStorage = (filter: RequestFilterDto) => {
    localStorage.setItem(CRYPTO_SPOT_FILTERS_STORAGE_KEY, toJson(filter))
}

const loadSelectedCryptoSpotFiltersFromStorage = (): RequestFilterDto => {
    const filter = localStorage.getItem(CRYPTO_SPOT_FILTERS_STORAGE_KEY)

    if (!filter) {
        return {}
    }
    return JSON.parse(filter)
}

const removeSelectedCryptoSpotFiltersFromStorage = () => {
    localStorage.removeItem(CRYPTO_SPOT_FILTERS_STORAGE_KEY)
}

const saveSelectedForexFiltersToStorage = (filter: RequestFilterDto) => {
    localStorage.setItem(FOREX_FILTERS_STORAGE_KEY, toJson(filter))
}

const loadSelectedForexFiltersFromStorage = (): RequestFilterDto => {
    const filter = localStorage.getItem(FOREX_FILTERS_STORAGE_KEY)

    if (!filter) {
        return {}
    }
    return JSON.parse(filter)
}

const removeSelectedForexFiltersFromStorage = () => {
    localStorage.removeItem(FOREX_FILTERS_STORAGE_KEY)
}

export {
    saveSelectedUsaFiltersToStorage,
    loadSelectedUsaFiltersFromStorage,
    saveSelectedUsaFiltersTemplateToStorage,
    loadSelectedUsaFiltersTemplateFromStorage,
    removeSelectedUsaFiltersFromStorage,
    removeSelectedUsaFilterTemplateFromStorage,
    saveSelectedCryptoFuturesFiltersToStorage,
    loadSelectedCryptoFuturesFiltersFromStorage,
    removeSelectedCryptoFuturesFiltersFromStorage,
    saveSelectedCryptoSpotFiltersToStorage,
    loadSelectedCryptoSpotFiltersFromStorage,
    removeSelectedCryptoSpotFiltersFromStorage,
    saveSelectedForexFiltersToStorage,
    loadSelectedForexFiltersFromStorage,
    removeSelectedForexFiltersFromStorage,
}
