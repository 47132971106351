const Page404 = () => {
    console.log('sdsd')
    return (
        <div>
            <p>404 Page not found</p>
        </div>
    )
}

export {
    Page404
}
