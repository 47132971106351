import {FormControl, MenuItem, Select} from "@mui/material";
import {ChartPeriod} from "../../../common/component/chart/ScreenerChart";
import React, {Dispatch, FC, SetStateAction, useMemo} from "react";
import {useThemeMode} from "../../../../_metronic/partials";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";

export type Props = {
    timeFrames: ChartPeriod[]
    defaultTimeFrame: ChartPeriod
    setDefaultTimeFrame: Dispatch<SetStateAction<ChartPeriod>>
}

export const DefaultTimeFrameComponent: FC<Props> = (props) => {
    const isDark = useThemeMode()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const {timeFrames, defaultTimeFrame, setDefaultTimeFrame} = props
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    const changeDefaultTimeFrame = (event: any) => {
        const newDefaultTimeFrame = event.target.value as ChartPeriod

        setDefaultTimeFrame(newDefaultTimeFrame)

        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.defaultTimeFrame = newDefaultTimeFrame

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
    }
    return (
        <div className='d-flex align-items-center'>
            <span className='text-gray-700 me-5 mx-5'>Default TF</span>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                <Select
                    labelId={"default-chart-period-label"}
                    value={defaultTimeFrame}
                    sx={{
                        input: { color: (isDark ? '#999999' : '#555555') },
                        color: (isDark ? '#999999' : '#555555'),
                        '.MuiSvgIcon-root ': {
                            fill: (isDark ? '#999999' : '#555555') + " !important",
                        }
                    }}
                    onChange={changeDefaultTimeFrame}
                >
                    {timeFrames.map(timeFrame => (
                        <MenuItem key={`default-chart-period-${timeFrame.toLowerCase()}`} value={timeFrame}>{timeFrame}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
