const LANG_STORAGE_KEY = 'screener-lang'

const saveLangToStorage = (lang: string) => {
    localStorage.setItem(LANG_STORAGE_KEY, lang)
}

const loadLangFromStorage = (): string | null => {
    return localStorage.getItem(LANG_STORAGE_KEY)
}

export {
    saveLangToStorage,
    loadLangFromStorage,
}
