import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    removeSelectedUsaFilterTemplateFromStorage,
    saveSelectedUsaFiltersToStorage
} from "../../../common/component/storage/FiltersStorage";
import {toFloat, toInt} from "../../../common/utils/converter/TypeConverter";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {RequestFilterDto} from "../../../common/utils/filter/FilterUtils";
import {UserSettingsMapper} from "../../../common/mapper/settings/UserSettingsMapper";
import {useUserSettingsContext} from "../../../common/provider/settings/UserSettingsDataProvider";
import {updateUserSettings} from "../../../common/request/settings/UserSettingsRequest";

type Props = {
    filterKey: string
    value: any
}

type FilterTemplateData = {
    title: string
    hint: string
    valueFrom: any | null
    valueTo: any | null
    isBoolean: boolean
}

const FilterTemplateItem: FC<Props>  = (props) => {
    const {t} = useTranslation()
    const {filterKey, value} = props
    const [data, setData] = useState<FilterTemplateData>({} as FilterTemplateData)

    useEffect(() => {
        let newData = {} as FilterTemplateData

        switch (filterKey) {
            case 'avg10DayVolumeFrom':
            case 'avg10DayVolumeTo':
                newData = {
                    title: t('content.filter.avg10DayVolume.title'),
                    hint: t('content.filter.avg10DayVolume.hint'),
                    valueFrom: (filterKey === 'avg10DayVolumeFrom' ? toInt(value) : null),
                    valueTo: (filterKey === 'avg10DayVolumeTo' ? toInt(value) : null),
                    isBoolean: false
                }
                break
            case 'priceFrom':
            case 'priceTo':
                newData = {
                    title: t('content.filter.price.title'),
                    hint: '',
                    valueFrom: (filterKey === 'priceFrom' ? toFloat(value) : null),
                    valueTo: (filterKey === 'priceTo' ? toFloat(value) : null),
                    isBoolean: false
                }
                break
            case 'atrFrom':
            case 'atrTo':
                newData = {
                    title: t('content.filter.atr.title'),
                    hint: t('content.filter.atr.hint'),
                    valueFrom: (filterKey === 'atrFrom' ? toFloat(value) : null),
                    valueTo: (filterKey === 'atrTo' ? toFloat(value) : null),
                    isBoolean: false
                }
                break
            default:
                break
        }
        setData(newData)
    }, []);

    return (
        <div className="mb-5" key={`template-filter-fields-${filterKey}`}>
            <label className="form-label fw-semibold">
                {data.title}:
            </label>
            <div className='d-flex'>
                {!data.isBoolean && (
                    <>
                        {data.valueFrom && t('content.filter.common.labels.from') + ': ' + data.valueFrom}
                        {data.valueTo && t('content.filter.common.labels.to') + ': ' + data.valueTo}
                    </>
                )}
                {data.isBoolean && (
                    <>
                        {data.valueFrom && t('content.filter.common.labels.from') + ': ' + data.valueFrom}
                        {data.valueTo && t('content.filter.common.labels.to') + ': ' + data.valueTo}
                    </>
                )}
            </div>
        </div>
    )
}

type FProps = {
    existsFilterTemplates: { [key: string]: any }
    setUpdateLoadedFilterTemplates: Dispatch<SetStateAction<boolean>>
    setRequestFilter: Dispatch<SetStateAction<RequestFilterDto>>
}

export const ScreenerFilterTemplate: FC<FProps> = (props) => {
    const {t} = useTranslation()
    const {userSettings, refetchUserSettingsData} = useUserSettingsContext()
    const {setUpdateLoadedFilterTemplates, existsFilterTemplates, setRequestFilter} = props
    const userSettingsMapper = useMemo(() => new UserSettingsMapper(), [])

    const isFilterDefined = (value: any) => {
        if (typeof value === 'boolean' && (value as boolean) === true) {
            return true
        } else if (typeof value === 'string' && (value as string) !== '') {
            return true
        } else if (typeof value === 'number' && toFloat(value) > 0.0) {
            return true
        }
        return false
    }
    const applyFilterTemplate = (hashKey: string) => {
        saveSelectedUsaFiltersToStorage(existsFilterTemplates[hashKey].filter)

        setRequestFilter(existsFilterTemplates[hashKey].filter)

        const marketSettings = userSettings.settings?.markets?.usaStocks
        marketSettings!!.selectedFilterTemplateId = hashKey

        updateUserSettings(
            userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
            refetchUserSettingsData
        )
    }
    const removeFilterTemplate = (hashKey: string) => {
        removeSelectedUsaFilterTemplateFromStorage(hashKey)

        setUpdateLoadedFilterTemplates(true)

        const marketSettings = userSettings.settings?.markets?.usaStocks

        if (marketSettings!!.selectedFilterTemplateId === hashKey) {
            marketSettings!!.selectedFilterTemplateId = null

            updateUserSettings(
                userSettingsMapper.updateUsaStocksMarket(userSettings, marketSettings!!),
                refetchUserSettingsData
            )
        }
    }
    return (
        <div>
            <button className="btn btn-sm btn-flex btn-light-primary menu-dropdown position-relative"
                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i className="bi bi-filter fs-5 me-1"></i>
                {t('content.controls.buttons.filterTemplate')}
            </button>
            <div className="menu menu-sub menu-sub-dropdown w-850px w-md-850px" data-kt-menu="true" style={{
                zIndex: 107,
                position: "fixed",
                inset: "0px 0px auto auto",
                margin: 0,
                transform: "transform: translate3d(-612.5px, 221px, 0px)"
            }} data-popper-placement="bottom-end">
                <div className="px-7 py-5">
                    <div className="text-gray-700 fs-5 text-dark fw-bold">{t('content.filterTemplate.title')}</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className='row gx-5 gx-xl-10'>
                    <div className='mb-5 mb-xl-10'>
                        <div className="px-7 py-5">
                            {existsFilterTemplates && Object.keys(existsFilterTemplates).map((hashKey, index) => (
                                <div className="d-flex flex-column content-justify-center flex-row-fluid mb-7" key={`template-${hashKey}`}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 me-4 fs-4" key={`template-header-${hashKey}`}>
                                            {existsFilterTemplates[hashKey].name}
                                            <OverlayTrigger
                                                key={`template-overlay-${hashKey}`}
                                                placement='right'
                                                overlay={
                                                    <Tooltip key={`template-overlay-tooltip-${hashKey}`}>
                                                        {Object.keys(existsFilterTemplates[hashKey].filter).map(key => (
                                                            isFilterDefined((existsFilterTemplates[hashKey].filter[key]) as any) && (
                                                                <FilterTemplateItem filterKey={hashKey}
                                                                                    value={existsFilterTemplates[hashKey][key]}/>
                                                            )
                                                        ))}
                                                    </Tooltip>
                                                }>

                                                <i className='bi bi-question-circle-fill mx-1'/>
                                            </OverlayTrigger>
                                        </div>
                                        <div className='text-gray-700 text-xxl-end' key={`template-body-${hashKey}`}>
                                            <button type="button"
                                                    className="btn btn-icon btn-sm btn-success me-7"
                                                    onClick={() => applyFilterTemplate(hashKey)}>
                                                <i className='bi bi-arrow-90deg-right fs-5'/>
                                            </button>
                                            <button type="button"
                                                    className="btn btn-icon btn-sm btn-danger"
                                                    onClick={() => removeFilterTemplate(hashKey)}>
                                                <i className='bi bi-trash fs-5'/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
