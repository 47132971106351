import React, {createContext, FC, useContext, useState} from "react";
import {WithChildren} from "../../../../_metronic/helpers";
import {createDefaultUserSettings, UserSettingsDto} from "../../dto/settings/UserSettingsDto";
import {getUserSettings} from "../../request/settings/UserSettingsRequest";

type UserSettingsContextDto = {
    userSettings: UserSettingsDto
    refetchUserSettingsData: () => void
}

const UserSettingsContext = createContext<UserSettingsContextDto>({
    userSettings: createDefaultUserSettings(),
    refetchUserSettingsData: () =>  {}
})

const UserSettingsProvider: FC<WithChildren> = ({children}) => {
    const [userSettings, setUserSettings] = useState(getUserSettings() || createDefaultUserSettings())
    const refetchUserSettingsData = () => {
        const newUserSettings = getUserSettings()

        if (newUserSettings) {
            setUserSettings(newUserSettings)
        }
    }

    return (
        <UserSettingsContext.Provider value={{userSettings, refetchUserSettingsData}}>
            {children}
        </UserSettingsContext.Provider>
    )
}

const useUserSettingsContext = () => {
    return useContext(UserSettingsContext)
}

export {
    UserSettingsProvider,
    useUserSettingsContext,
}
