import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function LanguagePath() {
    const { i18n } = useTranslation()
    const { lang } = useParams()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    useEffect(() => {
        if (lang && i18n.resolvedLanguage !== lang) {
            i18n.changeLanguage(lang)

            let newPathname = pathname

            i18n.languages.forEach(supportedLang => {
                newPathname = newPathname.replace(`/${supportedLang}`, '')
            })
            navigate("/" + i18n.language + newPathname, {replace: true})
        }
    }, [lang])

    return <Outlet />
}