export const toInt = (value: any): number => {
    try {
        const result = parseInt(value)

        return (!isNaN(result) ? result : 0)
    } catch (exception) {
        return 0
    }
}

export const toFloat = (value: any): number => {
    try {
        const result = parseFloat(value)

        return (!isNaN(result) ? result : 0.0)
    } catch (exception) {
        return 0.0
    }
}
